export const resources = {
  pl: {
    translation: {
      //meta tags
      "page-title-calc": "Kalkulator buildów Pride of Taern",
      "meta-description-calc":
        "Nie wiesz, ile punktów umiejętności będziesz mieć na danym poziomie? Chcesz zaplanować rozwój swojej postaci? Zajrzyj tutaj!",
      "page-title-home": "The Pride of Taern Toolbox",
      "meta-description-home":
        "Chcesz zaplanować rozwój postaci od 1 do 140 poziomu? Sprawdzić, ile wiedzy potrzebujesz na umiejętności? Zobaczyć spis rzadkich przedmiotów? Zajrzyj tutaj!",
      "page-title-know": "Kalkulator wiedzy The Pride of Taern",
      "meta-description-know":
        "Chcesz obliczyć, ile wiedzy potrzeba na danym poziomie, aby używać umiejętności za odpowiednią ilość PA? Sprawdź nasz kalkulator, który obliczy to za Ciebie!",
      //home
      h1: "The Pride of Taern Toolbox",
      created: "Stworzone przez",
      suggestions: "Propozycje i błędy można zgłaszać przez",
      "suggestions-target": "forum Taernu",
      "suggestion-target-link":
        "https://forum.taern.pl/viewtopic.php?f=279&t=56507",
      disclaimer:
        "Gra Taern, a także grafiki przedmiotów i logo, są własnością Whitemoon System.",
      "disclaimer-2": "Pozostałe grafiki pochodzą z",
      cookies:
        "Ta strona używa cookies. Kontynuując używanie jej, zgadzasz się na ich użycie w celach analitycznych.",
      "cookies-confirm": "Rozumiem",
      //classes
      Barbarzyńca: "Barbarzyńca",
      Sheed: "Sheed",
      Rycerz: "Rycerz",
      Druid: "Druid",
      "Mag Ognia": "Mag Ognia",
      Łucznik: "Łucznik",
      VooDoo: "VooDoo",
      barbarian: "Barbarzyńca",
      knight: "Rycerz",
      druid: "Druid",
      firemage: "Mag Ognia",
      archer: "Łucznik",
      voodoo: "VooDoo",
      sheed: "Sheed",
      //character form
      "Wybierz klasę": "Wybierz klasę",
      "Poziom postaci": "Poziom postaci",
      Zatwierdź: "Zatwierdź",
      //importing
      "importing-build": "Importowanie buildu...",
      "import-failed":
        "Importowanie nie powiodło się. Sprawdź czy link jest prawidłowy lub spróbuj ponownie później.",
      //items
      "Admiralski gronostaj": "Admiralski gronostaj",
      "Aeterus Passio": "Aeterus Passio",
      Alendry: "Alendry",
      Anabolik: "Anabolik",
      Angwallion: "Angwallion",
      "Aquariusy II v1": "Aquariusy II v1",
      "Aquariusy II v2": "Aquariusy II v2",
      Aqueniry: "Aqueniry",
      "Arcanscape II": "Arcanscape II",
      "Arhauty II": "Arhauty II",
      Ayol: "Ayol",
      Balast: "Balast",
      Bartaur: "Bartaur",
      Basileus: "Basileus",
      Batagur: "Batagur",
      Biltabandury: "Biltabandury",
      Ból: "Ból",
      "Brassary II": "Brassary II",
      Brunnle: "Brunnle",
      Bryza: "Bryza",
      Buoriany: "Buoriany",
      Caratris: "Caratris",
      "Cień Tarula": "Cień Tarula",
      "Cierń II": "Cierń II",
      Cierń: "Cierń",
      Cierpiętniki: "Cierpiętniki",
      Czacha: "Czacha",
      Czengsvesy: "Czengsvesy",
      Dagorilm: "Dagorilm",
      Danthum: "Danthum",
      "Davgretor II": "Davgretor II",
      Debba: "Debba",
      Derengil: "Derengil",
      Diabolo: "Diabolo",
      Disolver: "Disolver",
      Dmorlung: "Dmorlung",
      Dorbis: "Dorbis",
      Dracorporis: "Dracorporis",
      Egzekutor: "Egzekutor",
      Envile: "Envile",
      Erbaile: "Erbaile",
      Exuvium: "Exuvium",
      Fanga: "Fanga",
      "Fiskorl II": "Fiskorl II",
      Fraxy: "Fraxy",
      "Fulgur II": "Fulgur II",
      Gathril: "Gathril",
      "Geomorph Core": "Geomorph Core",
      "Gest Władcy": "Gest Władcy",
      Ghaitarog: "Ghaitarog",
      Gjolmar: "Gjolmar",
      "Gniew Zapomnianych": "Gniew Zapomnianych",
      Griv: "Griv",
      "Groza Seleny": "Groza Seleny",
      Grzebień: "Grzebień",
      "Hańba Seleny": "Hańba Seleny",
      Harttraum: "Harttraum",
      Htagan: "Htagan",
      Irkamale: "Irkamale",
      Ishelm: "Ishelm",
      Istav: "Istav",
      "Isthrimm II": "Isthrimm II",
      Isverd: "Isverd",
      Jeroszki: "Jeroszki",
      Karapaks: "Karapaks",
      Karlder: "Karlder",
      Khalam: "Khalam",
      Kil: "Kil",
      Koriatula: "Koriatula",
      Lawina: "Lawina",
      Lysmary: "Lysmary",
      Maiarot: "Maiarot",
      "Mallus Selenorum": "Mallus Selenorum",
      Markahn: "Markahn",
      Martumal: "Martumal",
      "Maska Lugusa": "Maska Lugusa",
      Mauremys: "Mauremys",
      "Miłość Morany": "Miłość Morany",
      Moczary: "Moczary",
      "Nadzieja Pokoleń": "Nadzieja Pokoleń",
      Navigon: "Navigon",
      "Nienawiść Draugula": "Nienawiść Draugula",
      Nit: "Nit",
      Nurt: "Nurt",
      Nurthil: "Nurthil",
      Obdartusy: "Obdartusy",
      "Objęcia Morany": "Objęcia Morany",
      "Obroża Władcy": "Obroża Władcy",
      "Ognisty Młot": "Ognisty Młot",
      "Opoka Bogów": "Opoka Bogów",
      Ortasis: "Ortasis",
      Ostolbin: "Ostolbin",
      Otwieracz: "Otwieracz",
      "Pamięć Morany": "Pamięć Morany",
      "Pancerz Dziesięciolecia": "Pancerz Dziesięciolecia",
      "Pancerz Komandorski": "Pancerz Komandorski",
      Pazury: "Pazury",
      Piroklast: "Piroklast",
      "Powrót Ivravula": "Powrót Ivravula",
      Promuris: "Promuris",
      "Przysięga Draugula": "Przysięga Draugula",
      Pysk: "Pysk",
      "Radius Electricum": "Radius Electricum",
      Rolrak: "Rolrak",
      Salmurn: "Salmurn",
      Sentrion: "Sentrion",
      "Serce Seleny": "Serce Seleny",
      Sidun: "Sidun",
      Sigil: "Sigil",
      Skiilfy: "Skiilfy",
      Skogan: "Skogan",
      "Skry Utoru": "Skry Utoru",
      "Smocze Skrzydło": "Smocze Skrzydło",
      "Smoczy Gnat": "Smoczy Gnat",
      Smuga: "Smuga",
      Sphaera: "Sphaera",
      Sturprang: "Sturprang",
      "Szpony Seimhi": "Szpony Seimhi",
      Szpony: "Szpony",
      Taehal: "Taehal",
      Tangnary: "Tangnary",
      Tasak: "Tasak",
      "Temary II": "Temary II",
      Tężec: "Tężec",
      Thorimmy: "Thorimmy",
      "Tirhel II": "Tirhel II",
      "Trójząb Admiralski": "Trójząb Admiralski",
      "Tsunami II v1": "Tsunami II v1",
      "Tsunami II v2": "Tsunami II v2",
      Udręki: "Udręki",
      Uguns: "Uguns",
      Ulga: "Ulga",
      Urntsul: "Urntsul",
      Vaekany: "Vaekany",
      Valazan: "Valazan",
      Varrvy: "Varrvy",
      Virral: "Virral",
      "Virthil II": "Virthil II",
      Virveny: "Virveny",
      Vogurun: "Vogurun",
      Vorleah: "Vorleah",
      "Władca Losu": "Władca Losu",
      "Wodny tancerz": "Wodny tancerz",
      Wzorek: "Wzorek",
      "Wzrok Głębin": "Wzrok Głębin",
      Xenothor: "Xenothor",
      Yurugu: "Yurugu",
      Zadry: "Zadry",
      "Zagłada Ludów": "Zagłada Ludów",
      Zalla: "Zalla",
      "Zemsta Ivravula": "Zemsta Ivravula",
      Ziraki: "Ziraki",
      "Allakaja Kondycji": "Allakaja Kondycji",
      "Allakaja Many": "Allakaja Many",
      Allenor: "Allenor",
      Ajana: "Ajana",
      Anahata: "Anahata",
      Attawa: "Attawa",
      "Buty Killarasuna": "Buty Killarasuna",
      "Buty Popiołów": "Buty Popiołów",
      "Buty Północy": "Buty Północy",
      "Buty Tąpnięcia": "Buty Tąpnięcia",
      "Buty Vorlingów": "Buty Vorlingów",
      "Buty Wymiarów": "Buty Wymiarów",
      "Całun Ludzkości": "Całun Ludzkości",
      "Gnaty Reffa": "Gnaty Reffa",
      Gorthdar: "Gorthdar",
      Gromy: "Gromy",
      "Gwiazda Tellabagu": "Gwiazda Tellabagu",
      "Gwiaździste tekko": "Gwiaździste tekko",
      Hadras: "Hadras",
      "Hełm Harmonii": "Hełm Harmonii",
      "Hełm Haugura": "Hełm Haugura",
      "Hełm Niełaski": "Hełm Niełaski",
      "Hełm Pioniera": "Hełm Pioniera",
      "Hełm Vorlingów": "Hełm Vorlingów",
      Ibura: "Ibura",
      Imisindo: "Imisindo",
      Kamuflaż: "Kamuflaż",
      "Kastet Pinari": "Kastet Pinari",
      "Kij Huraganu": "Kij Huraganu",
      "Korona Jastrzębia": "Korona Jastrzębia",
      "Korona Lasu": "Korona Lasu",
      "Korona Wiecznych Władców": "Korona Wiecznych Władców",
      "Kulosy Reffa": "Kulosy Reffa",
      "Laska Killarasuna": "Laska Killarasuna",
      "Laska Płomieni": "Laska Płomieni",
      "Latarnia Życia": "Latarnia Życia",
      "Łeb Reffa": "Łeb Reffa",
      "Łuk Pinari": "Łuk Pinari",
      Manpury: "Manpury",
      Meandry: "Meandry",
      Memento: "Memento",
      "Miecz Boreasza": "Miecz Boreasza",
      "Miecz Pinari": "Miecz Pinari",
      "Oblicze Sprawcy": "Oblicze Sprawcy",
      "Okrycie Wieczności": "Okrycie Wieczności",
      "Orcza Zbroja": "Orcza Zbroja",
      "Ostrze Haugura": "Ostrze Haugura",
      "Pas Luty": "Pas Luty",
      "Pas Killarasuna": "Pas Killarasuna",
      "Pas Ofiarowania": "Pas Ofiarowania",
      "Pas Ognia": "Pas Ognia",
      "Pas Powodzi": "Pas Powodzi",
      "Pas Równowagi": "Pas Równowagi",
      "Pas Trucizny": "Pas Trucizny",
      "Płaszcz Vorlingów": "Płaszcz Vorlingów",
      "Rękawice Czynu": "Rękawice Czynu",
      "Rękawice Haugura": "Rękawice Haugura",
      "Rękawice Killarasuna": "Rękawice Killarasuna",
      "Rękawice Oddalenia": "Rękawice Oddalenia",
      "Rękawice Północy": "Rękawice Północy",
      "Rękawice Suszy": "Rękawice Suszy",
      "Rękawice Toksyczne": "Rękawice Toksyczne",
      "Rękawice Wulkanów": "Rękawice Wulkanów",
      "Ryk Północy": "Ryk Północy",
      Skry: "Skry",
      "Słoneczny Wiatr": "Słoneczny Wiatr",
      "Spodnie Hekatomby": "Spodnie Hekatomby",
      "Spodnie Vorlingów": "Spodnie Vorlingów",
      "Spodnie Wieczności": "Spodnie Wieczności",
      "Starożytna Zbroja": "Starożytna Zbroja",
      "Szarfa Nieistnienia": "Szarfa Nieistnienia",
      Szeol: "Szeol",
      Szepty: "Szepty",
      "Szpony Szamana": "Szpony Szamana",
      "Tarcza Haugura": "Tarcza Haugura",
      "Tchnienie Przyszłości": "Tchnienie Przyszłości",
      "Tekko Balansu": "Tekko Balansu",
      Tęsknota: "Tęsknota",
      "Topór Pinari": "Topór Pinari",
      Vahany: "Vahany",
      Vaja: "Vaja",
      Washi: "Washi",
      Wicher: "Wicher",
      Wulkan: "Wulkan",
      "Zbroja Strzelca": "Zbroja Strzelca",
      "Zbroja Vorlingów": "Zbroja Vorlingów",
      "Zew Rytuału": "Zew Rytuału",
      Zgorzelniki: "Zgorzelniki",
      Żar: "Żar",
      Żmij: "Żmij",
      Biesagi: "Biesagi",
      Kirsile: "Kirsile",
      Kąsacz: "Kąsacz",
      Zgon: "Zgon",
      Agonia: "Agonia",
      Dreszcze: "Dreszcze",
      //skills
      "Cios pięścią": "Cios pięścią",
      "Okrzyk bojowy": "Okrzyk bojowy",
      "Rzut kamieniem": "Rzut kamieniem",
      Strzał: "Strzał",
      "Zwykły atak": "Zwykły atak",
      Ucieczka: "Ucieczka",
      Dyńka: "Dyńka",
      "Atak dwuręczny": "Atak dwuręczny",
      Taran: "Taran",
      Gruboskórność: "Gruboskórność",
      Gryzienie: "Gryzienie",
      Furia: "Furia",
      Zakażenie: "Zakażenie",
      Ryk: "Ryk",
      "Krytyczne uderzenie": "Krytyczne uderzenie",
      "Szybkie Cięcie": "Szybkie Cięcie",
      "Mierzony Cios": "Mierzony Cios",
      "Blok Tarczą": "Blok Tarczą",
      Trans: "Trans",
      Ochrona: "Ochrona",
      "Potężne Uderzenie": "Potężne Uderzenie",
      "Aura Czystości": "Aura Czystości",
      Poświęcenie: "Poświęcenie",
      "Siła Jedności": "Siła Jedności",
      "Magiczna iskra": "Magiczna iskra",
      "Wrażliwość na Ogień": "Wrażliwość na Ogień",
      "Ognista Sfera": "Ognista Sfera",
      Inkantacja: "Inkantacja",
      "Aura Rozproszenia": "Aura Rozproszenia",
      Podpalenie: "Podpalenie",
      "Kula ognia": "Kula ognia",
      "Deszcz ognia": "Deszcz ognia",
      Meteoryt: "Meteoryt",
      "Ukłucie lalki": "Ukłucie lalki",
      "Szpila w oko": "Szpila w oko",
      "Wyssanie duszy": "Wyssanie duszy",
      "Hak w brzuch": "Hak w brzuch",
      "Tarcza Cienia": "Tarcza cienia",
      "tarcza-cienia-desc": "Voodoo rozpraszają siłę ciosu, którego część zostaje zniwelowana, część przejęta przez manę, a dopiero to, co zostanie, dociera do czarodzieja. W walce z innymi graczami siła uderzenia nie ulega zmniejszeniu zanim zostanie przechwycona przez manę. Gdy poziom many Voodoo jest niski (15% całkowitej ilości) tarcza cienia przestaje wykazywać swoje właściwości.",
      Zatrucie: "Zatrucie",
      "Uderzenie cienia": "Uderzenie cienia",
      Otępienie: "Otępienie",
      "Aura cienia": "Aura cienia",
      "Ukazanie Śmierci": "Ukazanie Śmierci",
      "Lewy prawy": "Lewy prawy",
      "Front Kick": "Front Kick",
      "Kontrola oddechu": "Kontrola oddechu",
      "Dotkliwe uderzenie": "Dotkliwe uderzenie",
      "Cios w wątrobę": "Cios w wątrobę",
      "Latające kolano": "Latające kolano",
      Uniki: "Uniki",
      Haduoken: "Haduoken",
      "Uderzenie Chi": "Uderzenie Chi",
      "Precyzyjny strzał": "Precyzyjny strzał",
      "Krótkie spięcie": "Krótkie spięcie",
      "Zatruta Strzała": "Zatruta Strzała",
      "Wyostrzone zmysły": "Wyostrzone zmysły",
      "Ognista strzała": "Ognista strzała",
      "Piach w oczy": "Piach w oczy",
      "Strzał strategiczny": "Strzał strategiczny",
      "Lodowa strzała": "Lodowa strzała",
      "Grad strzał": "Grad strzał",
      Leczenie: "Leczenie",
      Odczarowanie: "Odczarowanie",
      "Rój os": "Rój os",
      Wtapianie: "Wtapianie",
      Wzmocnienie: "Wzmocnienie",
      Uderzenie: "Uderzenie",
      "Źródło natury": "Źródło natury",
      "Leczenie grupowe": "Leczenie grupowe",
      Korzenie: "Korzenie",
      Wataha: "Wataha",
      "Wyrwanie z korzeni": "Wyrwanie z korzeni",
      //skills calculator
      "Punkty umiejętności": "Punkty umiejętności",
      Reset: "Reset",
      "Umiejętności klasowe": "Umiejętności klasowe",
      "Umiejętności podstawowe": "Umiejętności podstawowe",
      "max-skill-lvl-reached": "Maks. poziom osiągnięty",
      "req-lvl-upgrade-skill": "Poz. wym. do awansu",
      //intro
      "intro-header-1": "Po co mi kalkulator buildów Pride of Taern?",
      "intro-desc-1":
        "Nie masz pojęcia, jakie są najlepszy itemy na twój level? Chcesz wiedzieć, czy po resecie starczy ci punktów, żeby wykupić odpowiednie umiejętności i nie chcesz zmarnować platyny? Na takie właśnie pytania odpowiada niniejszy kalkulator. Nieprzemyślane resety mogą was kosztować cenną platynę, którą przecież lepiej wydać inaczej. Zamiast ją marnować, lepiej użyć kalkulatora i sprawdzić, czy z obecnym poziomem uda ci się osiągnąć odpowiednio wysokie poziomy umiejętności i parametrów.",
      "intro-header-2":
        "Czy mogę jakoś podzielić się swoim buildem z innymi graczami Pride of Taern?",
      "intro-desc-2": `Jak najbardziej! Wystarczy, że klikniesz na przycisk "Eksportuj build" na samym dole ekranu, a następnie gotowy link do twojego buildu skopiujesz do schowka. Możesz potem wklejać go innym graczom na czacie w grze, na forum lub gdziekolwiek zechcesz.`,
      "intro-header-3":
        "Jak używać kalkulatora umiejętności i statystyk Pride of Taern?",
      "intro-desc-3-1":
        "Używanie kalkulatora jest bardzo proste - w formularzu powyżej wpisz:",
      "intro-desc-3-bullet-1": `Dla jakiej klasy chcesz wyliczyć statystyki i umiejętności (pole "Wybierz klasę").`,
      "intro-desc-3-bullet-2": `Dla jakiego poziomu postaci chcesz dokonać obliczeń (pole "Poziom postaci")`,
      "intro-desc-3-bullet-3": `Kliknij "Zatwierdź"`,
      "intro-desc-3-2": `Po przejściu powyższych kroków wyświetli się kalkulator. Na początku będzie widoczna zakładka z listą statystyk i ekwipunkiem, ale możesz również przejść do drugiej zakładki z listą umiejętności (oddzielnie umiejętności klasowe i te dla każdej klasy). Możesz użyć przycisków, aby zwiększyć bądź zmniejszyć poziom statystyki lub umiejętności. Kliknij na sloty w ekwipunku, aby wyekwipować przedmioty. Możesz również tworzyć własne przedmioty lub przedmioty sklepowe po kliknięciu na ikonkę młota i kowadła na liście przedmiotów.
      Dodatkowo jeśli chcesz rozpocząć dodawanie od nowa, użyj przycisku "Reset".`,
      "intro-header-4":
        "Niektóre przedmioty mają losowe statystyki, a niektóre z moich przedmiotów są ulepszone - jak mogę odzwierciedlić to w kalkulatorze?",
      "intro-desc-4-1":
        "Po wyekwipowaniu przedmioty, kliknij przycisk ulepszenia",
      "intro-desc-4-2":
        "Otworzy się menu, w którym będziesz mógł dowolnie modyfikować podstawowe parametry przedmiotu.",
      "intro-header-5": "Do czego służy ikonka kowadła",
      "intro-desc-5":
        "Po kliknięciu w tę ikonkę możecie sami stworzyć przedmiot! Dodatkowo funkcja ta umożliwia personalizację buildu i przedmiotu - możecie mu nadawać własną nazwę i obrazek!",
      "intro-header-6": "Czym jest The Pride of Taern?",
      "intro-desc-6":
        "The Pride of Taern to polska gra MMORPG, która łączy w sobie cechy klasycznych, izometrycznych RPG-ów z dynamicznym turowym systemem walki i dojrzałą, nieliniową fabułą. Znajdziesz w niej ponad 300 lokacji, 466 potworów, 774 bohaterów niezależnych, 40 bossów, setki potężnych przedmiotów. Dodatkowo ponad 100 questów i zadań, a także dynamiczne eventy.",
      //calculator
      "Statystyki i przedmioty": "Statystyki i przedmioty",
      Umiejętności: "Umiejętności",
      //build exporter
      "Eksportuj build": "Eksportuj build",
      "Link do twojego buildu": "Link do twojego buildu",
      "Skopiuj do schowka": "Skopiuj do schowka",
      "export-in-progress": "Eksportowanie w toku...",
      "export-error": "Wystąpił błąd, spróbuj ponownie później!",
      "build-copied":
        "Adres buildu skopiowany do schowka! Użyj Ctrl+V, aby wkleić go gdzie chcesz!",
      //level changer
      Poziom: "Poziom",
      //alert
      Zamknij: "Zamknij",
      //stats calculator
      strength: "Siła",
      agility: "Zręczność",
      power: "Moc",
      knowledge: "Wiedza",
      hp: "Punkty życia",
      mana: "Mana",
      endurance: "Kondycja",
      bluntRes: "Odp. na obuchowe",
      cutRes: "Odp. na sieczne",
      pierceRes: "Odp. na kłute",
      fireRes: "Odp. na ogień",
      energyRes: "Odp. na energię",
      curseRes: "Odp. na uroki",
      frostRes: "Odp. na zimno",
      "Punkty statystyk": "Punkty statystyk",
      Obrażenia: "Obrażenia",
      damage: "Obrażenia",
      "char-power": "Power",
      //item creator
      Wartość: "Wartość",
      "Stwórz własny przedmiot": "Stwórz własny przedmiot",
      "Wybierz parametr": "Wybierz parametr",
      "no-parameters-chosen": "Nie określono żadnych parametrów!",
      armor: "Pancerz",
      helmet: "Hełm",
      neck: "Naszyjnik",
      gloves: "Rękawice",
      weapon: "Broń",
      shield: "Tarcza",
      belt: "Pas",
      boots: "Buty",
      ring: "Pierścień",
      pants: "Spodnie",
      cape: "Płaszcz",
      "armor-count": "Własny pancerz {{number}}",
      "helmet-count": "Własny hełm {{number}}",
      "neck-count": "Własny naszyjnik {{number}}",
      "gloves-count": "Własne rękawice {{number}}",
      "weapon-count": "Własna broń {{number}}",
      "shield-count": "Własna tarcza {{number}}",
      "belt-count": "Własny pas {{number}}",
      "boots-count": "Własne buty {{number}}",
      "ring-count": "Własny pierścień {{number}}",
      "pants-count": "Własne spodnie {{number}}",
      "cape-count": "Własny płaszcz {{number}}",
      "vambraces-count": "Własne karwasze {{number}}",
      //item enhancement
      "change-stats-1": "Dodaj lub odejmij statystyki przedmiotu",
      "change-stats-2": "w ramach ulepszania bądź losowych statysyk",
      //special slot
      Nazwa: "Nazwa",
      "Wpisz nazwę": "Wpisz nazwę",
      "image-url": "Obrazek (URL)",
      "Wklej adres obrazka": "Wklej adres obrazka",
      //items list
      "create-item": "Stwórz własny przedmiot w tym slocie",
      "no-items": "Brak przedmiotów po zastosowaniu filtrów.",
      //equipment
      class: "Klasowy",
      rare: "Rzadkie",
      psychoRare: "Psycho",
      set: "Zestawy",
      epic: "Epiki",
      "unequip-all": "Czy na pewno chcesz zdjąć wszystkie przedmioty?",
      Tak: "Tak",
      Nie: "Nie",
      Resetuj: "Resetuj",
      "active-filters": "Lista aktywnych filtrów",
      "add-filters": "Dodaj filtry do list przedmiotów",
      "Zdejmij wszystkie przedmioty": "Zdejmij wszystkie przedmioty",
      "Buffy gildiowe": "Buffy gildiowe",
      //psychoslot
      "Dodatkowe właściwości": "Dodatkowe właściwości",
      "psycho-summary": "Podsumowanie właściwości psycho",
      //item slot
      "Ulepsz przedmiot": "Ulepsz przedmiot",
      //item tooltip
      "co poziom": "co poziom",
      Zestaw: "Zestaw",
      "Przedmiot gwiazdkowy": "Przedmiot gwiazdkowy",
      "req-class": "Wym. klasa",
      "req-level": "Wym. poziom",
      "req-strength": "Wym. siła",
      "req-agility": "Wym. zręczność",
      "req-power": "Wym. moc",
      "req-knowledge": "Wym. wiedza",
      "Poziom przedmiotu": "Poziom przedmiotu",
      Rozładowany: "Rozładowany",
      "Typ broni": "Typ broni",
      "Typ obrażeń": "Typ obrażeń",
      "Efekty zestawu": "Efekty zestawu",
      Jednoręczna: "Jednoręczna",
      Dwuręczna: "Dwuręczna",
      Obuchowe: "Obuchowe",
      Kłute: "Kłute",
      Sieczne: "Sieczne",
      //new build confirm dialogue
      "new-build-confirm":
        "Czy na pewno chcesz stworzyć nowy build? Obecny zostanie usunięty!",
      //sets
      "Resztki Reffa": "Resztki Reffa",
      "Okowy Snu": "Okowy Snu",
      "Światła Tellabagu": "Światła Tellabagu",
      "Przebranie Ludobójcy": "Przebranie Ludobójcy",
      "Zbroja Pinari": "Zbroja Pinari",
      "Gniew Żywiołów": "Gniew Żywiołów",
      "Żądło Bogów": "Żądło Bogów",
      "Sztorm Haugura": "Sztorm Haugura",
      "Ostrze Równowagi": "Ostrze Równowagi",
      "Ukazanie Killarasuna": "Ukazanie Killarasuna",
      "Szaty Płynnego Ognia": "Szaty Płynnego Ognia",
      "Pancerz Vorlingów": "Pancerz Vorlingów",
      "Dusza Świata": "Dusza Świata",
      "Oko Bogów": "Oko Bogów",
      "Pancerz Nieśmiertelności": "Pancerz Nieśmiertelności",
      "Pancerz Żywiołów": "Pancerz Żywiołów",
      "Pocałunek Miliona Gwiazd": "Pocałunek Miliona Gwiazd",
      "Zasłona Śmierci": "Zasłona Śmierci",
      //properties
      "Dodatkowe obrażenia od ognia": "Dodatkowe obrażenia od ognia",
      "Dodatkowe obrażenia od energii": "Dodatkowe obrażenia od energii",
      "Dodatkowe obrażenia od zimna": "Dodatkowe obrażenia od zimna",
      "Dodatkowe PA": "Dodatkowe PA",
      "Obrona dystansowa": "Obrona dystansowa",
      "Obrona przeciw urokom": "Obrona przeciw urokom",
      "Obrona wręcz": "Obrona wręcz",
      "Oszukać przeznaczenie": "Oszukać przeznaczenie",
      "Regeneracja kondycji": "Regeneracja kondycji",
      "Regeneracja many": "Regeneracja many",
      "Wyssanie many": "Wyssanie many",
      "Zużycie kondycji": "Zużycie kondycji",
      "Zużycie many": "Zużycie many",
      "Odporność na korzenie": "Odporność na korzenie",
      "Odporność na trafienie krytyczne": "Odporność na trafienie krytyczne",
      "Odporność na zamrożenie": "Odporność na zamrożenie",
      "Podwójne losowanie obrony": "Podwójne losowanie obrony",
      "Podwójne losowanie trafienia": "Podwójne losowanie trafienia",
      "Przełamanie dymków": "Przełamanie dymków",
      "Redukcja otrzymanych obrażeń": "Redukcja otrzymanych obrażeń",
      "Redukcja otrzymanych obrażeń biernych":
        "Redukcja otrzymanych obrażeń biernych",
      "Redukcja otrzymanych obrażeń krytycznych":
        "Redukcja otrzymanych obrażeń krytycznych",
      "Rozproszenie Holma": "Rozproszenie Holma",
      "Skok Farida": "Skok Farida",
      "Szansa na odczarowanie": "Szansa na odczarowanie",
      "Szansa na podwójny atak": "Szansa na podwójny atak",
      "Szansa na trafienie krytyczne": "Szansa na trafienie krytyczne",
      "Modyfikator obrażeń fizycznych": "Modyfikator obrażeń fizycznych",
      "Modyfikator obrażeń magicznych": "Modyfikator obrażeń magicznych",
      "Modyfikator trafień fizycznych": "Modyfikator trafień fizycznych",
      "Modyfikator trafień dystansowych": "Modyfikator trafień dystansowych",
      "Modyfikator trafień magicznych": "Modyfikator trafień magicznych",
      //item search
      "no-items-found": "Nie znaleziono pasujących przedmiotów.",
      //regen tooltip
      "punktów na rundę": "punktów na rundę",
      //guild buffs
      "Dodaj buffy gildiowe": "Dodaj buffy gildiowe",
      manaUsage: "Zużycie many",
      enduranceUsage: "Zużycie kondycji",
      regeneration: "Regeneracja",
      physRes: "Odp. fizyczne",
      "Poziom świątyni": "Poziom świątyni",
      //skill tooltip
      broń: "broń",
      Formuła: "Formuła",
      Trudność: "Trudność",
      "req-ap": "Wym. PA",
      "know-req": "Wym. wiedza na",
      "PA:": "PA",
      "current-kno": "Obecna wiedza",
      Osiągnięto: "Osiągnięto",
      self: "Rzucający",
      single: "Pojedynczy",
      group: "Grupowy",
      Cel: "Cel",
      "Rodzaj umiejętności": "Rodzaj umiejętności",
      attack: "Atak",
      debuff: "Urok negatywny",
      buff: "Urok pozytywny",
      "Strefa ataku": "Strefa ataku",
      melee: "Wręcz",
      ranged: "Dystansowy",
      mental: "Urok",
      "Atrybut do trafienia": "Atrybut do trafienia",
      "Siła leczenia": "Siła leczenia",
      "Modyfikator obrażeń": "Modyfikator obrażeń",
      "Szansa trafienia": "Szansa trafienia",
      "Czas trwania": "Czas trwania",
      "avg-damage": "Średnie obrażenia (bez uwzgl. odporności)",
      potrzeba: "potrzeba",
      //skill effects
      "Obrażenia od Potężnego uderzenia": "Obrażenia od Potężnego uderzenia",
      "Ilość bloków": "Ilość bloków",
      "Skuteczność bloku": "Skuteczność bloku",
      "Koszt many na rundę": "Koszt many na rundę",
      "Skuteczność ochrony": "Skuteczność ochrony",
      magicRes: "Odp. magiczne",
      "Zadawane obrażenia": "Zadawane obrażenia",
      "Skuteczność ataków": "Skuteczność ataków",
      "Wzmaga krwawienie": "Wzmaga krwawienie",
      Krwawienie: "Krwawienie",
      "Skuteczność obrony": "Skuteczność obrony",
      "Liczba uników": "Liczba uników",
      "Skuteczność uników": "Skuteczność uników",
      "Obrażenia od Uderzenia": "Obrażenia od Uderzenia",
      "Siła odczarowania": "Siła odczarowania",
      Unieruchomienie: "Unieruchomienie",
      "Szansa trafienia odłamkiem": "Szansa trafienia odłamkiem",
      "Szansa na efekt": "Szansa na efekt",
      "Obrażenia od Precyzyjnego strzału": "Obrażenia od Precyzyjnego strzału",
      "Utrata kondycji": "Utrata kondycji",
      "Szansa na zatrucie": "Szansa na zatrucie",
      "Szansa na zabicie": "Szansa na zabicie",
      "Suma rang pupili": "Suma rang pupili",
      "max-pets": "Maks. ilość pupili",
      "Obrażenia od ognia": "Obrażenia od ognia",
      "Obrażenia w HP": "Obrażenia w HP",
      "Obrażenia w manę": "Obrażenia w manę",
      "Reduktor straconej many": "Reduktor straconej many",
      //lazy loading
      loading: "Wczytywanie danych...",
      //routes
      home: "Strona główna",
      calc: "Kalkulator Buildów",
      //home
      "intro-header-7": "Do czego służy The Pride of Taern Toolbox?",
      "intro-desc-7": `The Pride of Taern Toolbox jest to zestaw narzędzi, które mają za zadanie ułatwić grę w The Pride of Taern. Znajdziesz tutaj przede wszystkim kalkulator buildów, który pozwoli Ci zaplanować rozwój swojej postaci od 1 do 140 poziomu. Jest to narzędzie kompleksowe, które oprócz dobrania sobie odpowiedniego ekwipunku, ulepszenia go i dodaniu punktów statystyk, pozwoli Ci także obliczyć zadawane obrażenia bądź wymaganą wiedzę (trudność) do umiejętności. W kalkulator buildów dostępne są również wszytkie tabelki z wiki Taernu, dzięki czemu wszystkie potrzebne Ci informacje znajdziesz w jednym miejscu.`,
      "intro-desc-8": `Jeśli nie masz ochoty wpisywać całego buildu, możesz także przejść do zakładki "Kalkulator wiedzy", która bez zbędnych dodatków pozwoli Ci obliczyć, ile wiedzy potrzebujesza na danym poziomie postaci, aby używać umiejętności za odpowiednią ilość PA. Zawiera ona również tabelki wiedzy (trudności), dzięki którym dowiesz się, jak trudne są poszczególne umiejętności.`,
      "intro-desc-9": `Zakładka "Lista przedmiotów" z kolei zawiera spis wszystkich przedmiotów dostępnych dla graczy w The Pride of Taern. Znajdują się tam przedmioty rzadkie (rary), ale również psychorary i najważniejsze z nich - epiki. Korzystając z tej zakładki będziesz mógł filtrować przedmioty po interesujących Cię statystykach, wyszukiwać je po nazwie, a także wiele różnych rzeczy. Tutaj również nie musisz specjalnie tworzyć buildu dla swojej postaci, aby obejrzeć spis przedmiotów.`,
      //error
      error: "Błąd 404 - Nie znaleziono strony",
      "error-desc":
        "Któż sprowadził Cię w ten zakamarek internetu? Niestety nic tutaj nie znajdziesz. Wypróbuj za to nasze narzędzia:",
      //know calc
      "know-calc": "Kalkulator Wiedzy",
      "Wymagana wiedza dla poziomu": "Wymagana wiedza dla poziomu",
      na: "na",
      "buff-desc":
        "Aby zobaczyć wymaganą wiedzę dla danej umiejętności, musisz ustawić dla niej odpowiedni poziom (przy użyciu przycisków plus i minus). Po ustawieniu poziomu i rozwinięciu opisu umiejętności, zobaczysz wymaganą wiedzę na różne ilości PA.",
      "intro-header-1-know": "Po co mi kalkulator wiedzy The Pride of Taern?",
      "intro-desc-1-know": `Kalkulator wiedzy The Pride of Taern to narzędzie, które bez zbędnych dodatków pozwoli Ci obliczyć, ile wiedzy potrzebujesza na danym poziomie postaci, aby używać umiejętności za odpowiednią ilość PA. Zawiera on również tabelki wiedzy (trudności), dzięki którym dowiesz się, jak trudne są poszczególne umiejętności.`,
      "intro-header-3-know":
        "Jak używać kalkulatora wiedzy The Pride of Taern?",
      "intro-desc-3-2-know": `Po przejściu powyższych kroków wyświetli się kalkulator z listą umiejętności dla danej klasy, które wymagają wiedzy do rzucenia (a więc posiadają pewną trudność). Aby zobaczyć wymaganą wiedzę dla danej umiejętności, musisz ustawić dla niej odpowiedni poziom (przy użyciu przycisków plus i minus). Po ustawieniu poziomu i rozwinięciu opisu umiejętności, zobaczysz wymaganą wiedzę na różne ilości PA.`,
      //items list
      "items-display": "Lista przedmiotów",
      Rzadkość: "Rzadkość",
      Klasa: "Klasa",
      Atrybut: "Atrybut",
      Sortowanie: "Sortowanie",
      reqLvl: "Wym. poziom",
      Rodzaj: "Rodzaj",
      "items-intro": `Ta zakładka zawiera spis wszystkich przedmiotów rzadkich (rarów), psychorarów, setowych i epickich dostępnych dla graczy w The Pride of Taern. Listę przedmiotów możesz filtrować po interesujących Cię statystykach lub po prostu wyszukać przedmiot po nazwie. Dostępna jest również opcja posortowania gotowej listy.`,
      "Obrazek": "Obrazek",
      "Wymagania": "Wymagania",
      "Statystyki": "Statystyki",
      "Psychowłaściwości": "Psychowłaściwości",
      "items-display-description": "Znajdziesz tutaj spis przedmiotów rzadkich, psychorarów, setowych i epickich dostępnych dla graczy w The Pride of Taern. Kliknij i zobacz je wszystkie!",
      //nowe
      "Ładunki": "Ładunki",
      "Amok": "Amok",
      "Tąpnięcie": "Tąpnięcie",
      "Wirujące ostrze": "Wirujące ostrze",
      "Naturalna odporność": "Naturalna odporność",
      "Obniżenie poziomu debuffów": "Obniżenie poziomu debuffów",
      "Czas trwania unieruchomienia": "Czas trwania unieruchomienia"
    },
  },
  en: {
    translation: {
      //home
      h1: "The Pride of Taern Toolbox",
      created: "Created by",
      suggestions: "Please post all suggestions and feedback on",
      "suggestions-target": "the Taern forum",
      "suggestion-target-link":
        "https://forum.taern.com/viewtopic.php?f=5&t=23506",
      disclaimer:
        "Pride of Taern, all item images and the logo are a property of Whitemoon System.",
      "disclaimer-2": "All other images are from",
      cookies:
        "This website uses cookies. By continuing to use it, you consent to cookie usage for analytical purposes.",
      "cookies-confirm": "I understand",
      //meta tags
      "page-title-calc": "Pride of Taern Build Calculator",
      "meta-description-calc":
        "Curious how many skills points you'll have on a certaon level? Want to plan your character's development? Take a look here!",
      "page-title-home": "The Pride of Taern Toolbox",
      "meta-description-home":
        "Do you want to plan your character's development from 1 to 140? Check how much knowledge you need for your abilities? Find all rare items? Take a look here!",
      "page-title-know": "Pride of Taern Knowledge Calculator",
      "meta-description-know":
        "Do you want to know how much knowledge you need to use your abilities for the right number of AP? Our calculator will calculate this for you!",
      //classes
      Barbarzyńca: "Barbarian",
      Sheed: "Sheed",
      Rycerz: "Knight",
      Druid: "Druid",
      "Mag Ognia": "Fire Mage",
      Łucznik: "Archer",
      VooDoo: "VooDoo",
      barbarian: "Barbarian",
      knight: "Knight",
      druid: "Druid",
      firemage: "Fire Mage",
      archer: "Archer",
      voodoo: "VooDoo",
      sheed: "Sheed",
      //character form
      "Wybierz klasę": "Choose Your Class",
      "Poziom postaci": "Character Level",
      Zatwierdź: "Submit",
      //importing
      "importing-build": "Importing in progress...",
      "import-failed":
        "Importing failed. Please check if your link is correct or try again later.",
      //items
      "Admiralski gronostaj": "Admiral's Ermine Robe",
      "Aeterus Passio": "Aeterus Passio",
      Alendry: "Alenders",
      Anabolik: "Anabolic",
      Angwallion: "Angvallion",
      "Aquariusy II v1": "Aquariuses II v1",
      "Aquariusy II v2": "Aquariuses II v2",
      Aqueniry: "Aquenirs",
      "Arcanscape II": "Arcanscape II",
      "Arhauty II": "Arhauts",
      Ayol: "Ayol",
      Balast: "Ballast",
      Bartaur: "Bartaur",
      Basileus: "Basileus",
      Batagur: "Batagur",
      Biltabandury: "Biltabandurs",
      Ból: "Pain",
      "Brassary II": "Brassars II",
      Brunnle: "Brunnle",
      Bryza: "Breeze",
      Buoriany: "Buorians",
      Caratris: "Caratris",
      "Cień Tarula": "Tarul's Shadow",
      "Cierń II": "Thorn II",
      Cierń: "Thorn",
      Cierpiętniki: "Sufferers",
      Czacha: "Skull",
      Czengsvesy: "Chengsvesy",
      Dagorilm: "Dagorilm",
      Danthum: "Danthum",
      "Davgretor II": "Davgretor II",
      Debba: "Debba",
      Derengil: "Derengil",
      Diabolo: "Diabolo",
      Disolver: "Dissolver",
      Dmorlung: "Dmorlung",
      Dorbis: "Dorbis",
      Dracorporis: "Dracorporis",
      Egzekutor: "Executioner",
      Envile: "Envils",
      Erbaile: "Erbaile",
      Exuvium: "Exuvium",
      Fanga: "Fanga",
      "Fiskorl II": "Feescorl II",
      Fraxy: "Fraxes",
      "Fulgur II": "Fulgur II",
      Gathril: "Gathril",
      "Geomorph Core": "Geomorph Core",
      "Gest Władcy": "Royal Gesture",
      Ghaitarog: "Ghaitarog",
      Gjolmar: "Gjolmar",
      "Gniew Zapomnianych": "Wrath of the Forgotten",
      Griv: "Griv",
      "Groza Seleny": "Selena's Dread",
      Grzebień: "Crest",
      "Hańba Seleny": "Selena's Shame",
      Harttraum: "Harttraum",
      Htagan: "Htagan",
      Irkamale: "Irkamals",
      Ishelm: "Ishelm",
      Istav: "Istav",
      "Isthrimm II": "Isthrimm II",
      Isverd: "Isverd",
      Jeroszki: "Yeroshky",
      Karapaks: "Karapax",
      Karlder: "Karlder",
      Khalam: "Khalam",
      Kil: "Keel",
      Koriatula: "Coriatula",
      Lawina: "Avalanche",
      Lysmary: "Lysmars",
      Maiarot: "Mayarot",
      "Mallus Selenorum": "Mallus Selenorum",
      Markahn: "Markahn",
      Martumal: "Martumal",
      "Maska Lugusa": "Lugus's mask",
      Mauremys: "Mauremys",
      "Miłość Morany": "Morana's Love",
      Moczary: "Marshes",
      "Nadzieja Pokoleń": "Generation's Hope",
      Navigon: "Navigon",
      "Nienawiść Draugula": "Hate of Draugul",
      Nit: "Rivet",
      Nurt: "Current",
      Nurthil: "Nurthil",
      Obdartusy: "Ragamuffins",
      "Objęcia Morany": "Morana's Embrace",
      "Obroża Władcy": "Master's Collar",
      "Ognisty Młot": "Firehammer",
      "Opoka Bogów": "Bastion of Gods",
      Ortasis: "Ortasis",
      Ostolbin: "Ostolbyn",
      Otwieracz: "Opener",
      "Pamięć Morany": "Morana's Memory",
      "Pancerz Dziesięciolecia": "Anniversary Armor",
      "Pancerz Komandorski": "Commander Armor",
      "Pas Luty": "Cold Belt",
      Pazury: "Claws",
      Piroklast: "Piroclast",
      "Powrót Ivravula": "Return of Ivravul",
      Promuris: "Promuris",
      "Przysięga Draugula": "Pledge of Draugul",
      Pysk: "Snout",
      "Radius Electricum": "Radius Electricum",
      Rolrak: "Rolrak",
      Salmurn: "Salmurn",
      Sentrion: "Sentryon",
      "Serce Seleny": "Selena's heart",
      Sidun: "Sidun",
      Sigil: "Sigil",
      Skiilfy: "Skiilfes",
      Skogan: "Skogan",
      "Skry Utoru": "Utorian Sparks",
      "Smocze Skrzydło": "Dragon's Wing",
      "Smoczy Gnat": "Dragon's Bone",
      Smuga: "Gleam",
      Sphaera: "Sphaera",
      Sturprang: "Sturprang",
      "Szpony Seimhi": "Seimhi Claws",
      Szpony: "Claws",
      Taehal: "Taehal",
      Tangnary: "Tangnars",
      Tasak: "Cleaver",
      "Temary II": "Runic Sash",
      Tężec: "Tetanus",
      Thorimmy: "Thorimms",
      "Tirhel II": "Tirhel II",
      "Trójząb Admiralski": "Admiral trident",
      "Tsunami II v1": "Tsunami II v1",
      "Tsunami II v2": "Tsunami II v2",
      Udręki: "Torments",
      Uguns: "Uguns",
      Ulga: "Respite",
      Urntsul: "Urntsul",
      Vaekany: "Vaekans",
      Valazan: "Valazan",
      Varrvy: "Varrvy",
      Virral: "Virral",
      "Virthil II": "Virthil II",
      Virveny: "Virvens",
      Vogurun: "Vogurun",
      Vorleah: "Vorleah",
      "Władca Losu": "Fate's Master",
      "Wodny tancerz": "Water dancer",
      Wzorek: "Pattern",
      "Wzrok Głębin": "Sights of Depths",
      Xenothor: "Xenothor",
      Yurugu: "Yurugu",
      Zadry: "Splinters",
      "Zagłada Ludów": "Nation's Doom",
      Zalla: "Zalla",
      "Zemsta Ivravula": "Revenge of Ivravul",
      Ziraki: "Zeeraks",
      "Allakaja Kondycji": "Allakaya of Stamina",
      "Allakaja Many": "Allakaya of Mana",
      Ajana: "Aina",
      Allenor: "Allenor",
      Anahata: "Anahata",
      Attawa: "Attawa",
      "Buty Killarasuna": "Killarasun's Boots",
      "Buty Popiołów": "Boots of Ashes",
      "Buty Północy": "Boots of the North",
      "Buty Tąpnięcia": "Boots of Earthquake",
      "Buty Vorlingów": "Vorling's Shoes",
      "Buty Wymiarów": "Boots of Dimensions",
      "Całun Ludzkości": "Humanity Shroud",
      "Gnaty Reffa": "Reff's Fores",
      Gorthdar: "Gorthdar",
      Gromy: "Thunders",
      "Gwiazda Tellabagu": "Star of Tellabag",
      "Gwiaździste tekko": "Stellar tekko",
      Hadras: "Hadras",
      "Hełm Harmonii": "Helmet of Harmony",
      "Hełm Haugura": "Haugur's Helmet",
      "Hełm Niełaski": "Helmet of Disgrace",
      "Hełm Pioniera": "Pioneer's Helmet",
      "Hełm Vorlingów": "Vorling's Helmet",
      Ibura: "Ibura",
      Imisindo: "Imisindo",
      Kamuflaż: "Camouflage",
      "Kastet Pinari": "Pinari Knuckle-duster",
      "Kij Huraganu": "Hurricane Staff",
      "Korona Jastrzębia": "Hawk's Crown",
      "Korona Lasu": "Forest Crown",
      "Korona Wiecznych Władców": "Crown of Eternal Lords",
      "Kulosy Reffa": "Reff's Hinds",
      "Laska Killarasuna": "Killarasun's Staff",
      "Laska Płomieni": "Flame Staff",
      "Latarnia Życia": "Lantern of Life",
      "Łeb Reffa": "Reff's Scalp",
      "Łuk Pinari": "Pinari Bow",
      Manpury: "Manpurs",
      Meandry: "Meanders",
      Memento: "Memento",
      "Miecz Boreasza": "Boreash's Sword",
      "Miecz Pinari": "Pinari Sword",
      "Oblicze Sprawcy": "Perpetrator's Face",
      "Okrycie Wieczności": "Mantle of Eternity",
      "Orcza Zbroja": "Orcish Armor",
      "Ostrze Haugura": "Haugur's Blade",
      "Pas Killarasuna": "Killarasun's Belt",
      "Pas Ofiarowania": "Sacrificial Belt",
      "Pas Ognia": "Belt of Fire",
      "Pas Powodzi": "Belt of Flood",
      "Pas Równowagi": "Belt of Equilibrium",
      "Pas Trucizny": "Belt of Poison",
      "Płaszcz Vorlingów": "Vorling cape",
      "Rękawice Czynu": "Gloves of Action",
      "Rękawice Haugura": "Haugur's Gloves",
      "Rękawice Killarasuna": "Killarasun's Gloves",
      "Rękawice Oddalenia": "Gloves of Distancing",
      "Rękawice Północy": "Gloves of the North",
      "Rękawice Suszy": "Gloves of Drought",
      "Rękawice Toksyczne": "Toxic Gloves",
      "Rękawice Wulkanów": "Volcano Gloves",
      "Ryk Północy": "Roar of the North",
      Skry: "Sparks",
      "Słoneczny Wiatr": "Solar Wind",
      "Spodnie Hekatomby": "Hecatomb Trousers",
      "Spodnie Vorlingów": "Vorling greaves",
      "Spodnie Wieczności": "Greaves of Eternity",
      "Starożytna Zbroja": "Ancient Armor",
      "Szarfa Nieistnienia": "Sash of Inexistence",
      Szeol: "Sheol",
      Szepty: "Whispers",
      "Szpony Szamana": "Shaman Claws",
      "Tarcza Haugura": "Haugur's Shield",
      "Tchnienie Przyszłości": "Breath of the Future",
      "Tekko Balansu": "Tekko of Balance",
      Tęsknota: "Longing",
      "Topór Pinari": "Pinari Axe",
      Vahany: "Vahans",
      Vaja: "Vaja",
      Washi: "Washi",
      Wicher: "Gale",
      Wulkan: "Volcano",
      "Zbroja Strzelca": "Marksman's Armor",
      "Zbroja Vorlingów": "Vorling Armor",
      "Zew Rytuału": "Ritual's Cry",
      Zgorzelniki: "Infernos",
      Żar: "Heat",
      Żmij: "Wurm",
      Biesagi: "Saddlebags",
      Kirsile: "Kirsils",
      Kąsacz: "Biter",
      Zgon: "Demise",
      Agonia: "Agony",
      Dreszcze: "Shivers",
      //skills
      "Cios pięścią": "Punch",
      "Okrzyk bojowy": "War Cry",
      "Rzut kamieniem": "Stone Throw",
      Strzał: "Shoot",
      "Zwykły atak": "Strike",
      Ucieczka: "Flee",
      Dyńka: "Headbutt",
      "Atak dwuręczny": "Two-handed Strike",
      Taran: "Ram",
      Gruboskórność: "Thick Skin",
      Gryzienie: "Bite",
      Furia: "Fury",
      Zakażenie: "Infection",
      Ryk: "Roar",
      "Krytyczne uderzenie": "Critical Strike",
      "Szybkie Cięcie": "Quick Slash",
      "Mierzony Cios": "Calculated Strike",
      "Blok Tarczą": "Shield Block",
      Trans: "Trance",
      Ochrona: "Protection",
      "Potężne Uderzenie": "Mighty Blow",
      "Aura Czystości": "Purity Aura",
      Poświęcenie: "Sacrifice",
      "Siła Jedności": "Power of Unity",
      "Magiczna iskra": "Magical Spark",
      "Wrażliwość na Ogień": "Fire Vulnerability",
      "Ognista Sfera": "Fire Shield",
      Inkantacja: "Incantation",
      "Aura Rozproszenia": "Dispersion",
      Podpalenie: "Arson",
      "Kula ognia": "Fireball",
      "Deszcz ognia": "Rain of Fire",
      Meteoryt: "Meteorite",
      "Ukłucie lalki": "Pierce Doll",
      "Szpila w oko": "Eye Puncture",
      "Wyssanie duszy": "Drain Soul",
      "Hak w brzuch": "Rip Stomach",
      "Tarcza Cienia": "Shadow Shield",
      "tarcza-cienia-desc": "Voodoos can defend against incoming attacks by weakening them. Some of the force of the attacks is dispersed, some deflected by mana, and the caster is hit only with what remains of it. When fighting with other players, the force is not dispersed prior to being deflected by mana. When the amount of mana gets low (15% of total mana), Shadow Shield no longer works.",
      Zatrucie: "Poison",
      "Uderzenie cienia": "Shadow Strike",
      Otępienie: "Dementia",
      "Aura cienia": "Shadow Aura",
      "Ukazanie Śmierci": "Face of Death",
      "Lewy prawy": "One-two Punch",
      "Front Kick": "Front Kick",
      "Kontrola oddechu": "Breath Control",
      "Dotkliwe uderzenie": "Cheap Shot ",
      "Cios w wątrobę": "Liver Punch",
      "Latające kolano": "Flying Knee",
      Uniki: "Evasion",
      Haduoken: "Haduoken",
      "Uderzenie Chi": "Chi Punch",
      "Precyzyjny strzał": "Precise Shot",
      "Krótkie spięcie": "Short-circuit ",
      "Zatruta Strzała": "Poisoned Arrow",
      "Wyostrzone zmysły": "Sharpened Senses",
      "Ognista strzała": "Fire Arrow",
      "Piach w oczy": "Sand in Eyes",
      "Strzał strategiczny": "Strategic Shot",
      "Lodowa strzała": "Ice Arrow",
      "Grad strzał": "Hail of Arrows",
      Leczenie: "Healing",
      Odczarowanie: "Dispel",
      "Rój os": "Swarm",
      Wtapianie: "Fade",
      Wzmocnienie: "Invigoration",
      Uderzenie: "Bash",
      "Źródło natury": "Spring of Nature",
      "Leczenie grupowe": "Mass Heal",
      Korzenie: "Roots",
      Wataha: "The Pack",
      "Wyrwanie z korzeni": "Root Rip",
      //skills calculator
      "Punkty umiejętności": "Skill Points",
      Reset: "Reset",
      "Umiejętności klasowe": "Class Skills",
      "Umiejętności podstawowe": "Basic Skills",
      "max-skill-lvl-reached": "Max level reached",
      "req-lvl-upgrade-skill": "Lvl. req. to upgrade",
      //intro
      "intro-header-1": "Why do I need the Pride of Taern Build Calculator?",
      "intro-desc-1":
        "Don't know what the best items for you level are? Would you like to know if you're going to have enough skill and attribute points after a reset because you don't want to waste platinum? The calculator provides answers to these questions. Rash resets can cost you a lot of platinum and I'm sure you'd rather spend it in a different way. Instead of wasting it, use the calculator to check if you can have high enough attributes and skill levels with your current level.",
      "intro-header-2":
        "Is there any way for me to share my build with other Pride of Taern players?",
      "intro-desc-2": `Of course! All you need to do is click the Export Build button at the bottom of the screen. You will see a link to your build that you can copy and then paste wherever you want.`,
      "intro-header-3":
        "How do I use the skills and attributes calculator for Pride of Taern?",
      "intro-desc-3-1":
        "Using the calculator is very simple. Just fill out the form above with:",
      "intro-desc-3-bullet-1": `The class you want to add the skills and attributes to (the Choose Your Class field).`,
      "intro-desc-3-bullet-2": `The level for which you want to make calculations (the Character Level field).`,
      "intro-desc-3-bullet-3": `Click Submit.`,
      "intro-desc-3-2": `Once the above steps are complete, you'll see the calculator. First you'll see the tab with a list of attributes and equipment, but you can also click on the other tab to see skills (both base and class skills). You can use the buttons to increase/decrease attributes and upgrade/downgrade skills. Click on equipment slots to equip items. You can also create your own items or shop items using the hammer and anvil icon on the items list.
      If you want to start your calculations again, click the Reset button.`,
      "intro-header-4":
        "Some items have random attribute bonuses and I upgrade my equipment regularly. How can I achieve this in the calculator?",
      "intro-desc-4-1": "After equipping an item, click on the upgrade button",
      "intro-desc-4-2":
        "A new menu will open where you will be able to modify the attributes of the item as you see fit.",
      "intro-header-5": "What is the anvil icon used for",
      "intro-desc-5":
        "After clicking the icon, you'll be able to create your own item! This feature can also be used to personalize your build by adding a name and an image to the item!",
      "intro-header-6": "What is The Pride of Taern?",
      "intro-desc-6":
        "The Pride of Taern is a Polish MMORPG which combines features of classic isometric RPGs with a dynamic, turn-based combat system and a mature, non-linear plot. It contains over 300 locations, 466 monsters, 774 NPCs, 40 bosses, hundreds of powerful items. Also over 100 quests, tasks and dynamic events.",
      "Statystyki i przedmioty": "Attributes and Items",
      Umiejętności: "Skills",
      //build exporter
      "Eksportuj build": "Export Build",
      "Link do twojego buildu": "Link to Your Build",
      "Skopiuj do schowka": "Copy to Clipboard",
      "export-in-progress": "Export in progress...",
      "export-error": "An error has occured, please try again later!",
      "build-copied":
        "The link to your build has been copied to clipboard! Press Ctrl+V to paste it wherever you want!",
      //level changer
      Poziom: "Level",
      //alert
      Zamknij: "Close",
      //stats calculator
      strength: "Strength",
      agility: "Dexerity",
      power: "Power",
      knowledge: "Knowledge",
      hp: "HP",
      mana: "Mana",
      endurance: "Stamina",
      bluntRes: "Crushing Res.",
      cutRes: "Slashing Res.",
      pierceRes: "Piercing Res.",
      fireRes: "Fire Res.",
      energyRes: "Energy Res.",
      curseRes: "Mental Res.",
      frostRes: "Cold Res.",
      "Punkty statystyk": "Attribute Points",
      Obrażenia: "Damage",
      damage: "Damage",
      "char-power": "Character Power",
      //item creator
      Wartość: "Value",
      "Stwórz własny przedmiot": "Create a custom item",
      "Wybierz parametr": "Choose attribute",
      "no-parameters-chosen": "No attribute has been chosen!",
      armor: "Armor",
      helmet: "Helmet",
      neck: "Necklace",
      gloves: "Gloves",
      weapon: "Weapon",
      shield: "Shield",
      belt: "Belt",
      boots: "boots",
      ring: "Ring",
      pants: "Pants",
      cape: "Cape",
      "armor-count": "Custom armor {{number}}",
      "helmet-count": "Custom helmet {{number}}",
      "neck-count": "Custom necklace {{number}}",
      "gloves-count": "Custom gloves {{number}}",
      "weapon-count": "Custom weapon {{number}}",
      "shield-count": "Custom shield {{number}}",
      "belt-count": "Custom belt {{number}}",
      "boots-count": "Custom boots {{number}}",
      "ring-count": "Custom ring {{number}}",
      "pants-count": "Custom pants {{number}}",
      "cape-count": "Custom cape {{number}}",
      "vambraces-count": "Custom vambraces {{number}}",
      //item enhancement
      "change-stats-1": "Increse/decraese attributes on items",
      "change-stats-2": "to account for upgrades or random attributes",
      //special slot
      Nazwa: "Name",
      "Wpisz nazwę": "Enter name",
      "image-url": "Image (URL)",
      "Wklej adres obrazka": "Paste image URL",
      //items list
      "create-item": "Create a new item in this slot",
      "no-items": "No items after filters have been applied.",
      //equipment
      class: "Class",
      rare: "Rares",
      psychoRare: "Psycho",
      set: "Sets",
      epic: "Epics",
      "unequip-all": "Are you sure you want to unequip all items?",
      Tak: "Yes",
      Nie: "No",
      Resetuj: "Reset",
      "active-filters": "List of active filters",
      "add-filters": "Add filters to item lists",
      "Zdejmij wszystkie przedmioty": "Unequip all items",
      "Buffy gildiowe": "Guild buffs",
      //psychoslot
      "Dodatkowe właściwości": "Additional Properties",
      "psycho-summary": "Psycho properties summary",
      //item slot
      "Ulepsz przedmiot": "Upgrade item",
      //item tooltip
      "co poziom": "per level",
      Zestaw: "Set",
      "Przedmiot gwiazdkowy": "Star item",
      "req-class": "Req. class",
      "req-level": "Req. level",
      "req-strength": "Req. strength",
      "req-agility": "Req. dexterity",
      "req-power": "Req. power",
      "req-knowledge": "Req. knowledge",
      "Poziom przedmiotu": "Item level",
      Rozładowany: "Not charged",
      "Typ broni": "Weapon type",
      "Typ obrażeń": "Damage type",
      "Efekty zestawu": "Set properties",
      Jednoręczna: "One-handed",
      Dwuręczna: "Two-handed",
      Obuchowe: "Crushing",
      Kłute: "Piercing",
      Sieczne: "Slashing",
      //new build confirm dialogue
      "new-build-confirm":
        "Are you sure you want to create a new build? The current one will be removed!",
      //sets
      "Resztki Reffa": "Reff's Remains",
      "Okowy Snu": "Dream Shackles",
      "Światła Tellabagu": "Lights of Tellabag",
      "Przebranie Ludobójcy": "Murderer's Guise",
      "Zbroja Pinari": "Pinari Armor",
      "Gniew Żywiołów": "Fury of the Elements",
      "Żądło Bogów": "Gods' Sting",
      "Sztorm Haugura": "Haugur's Storm",
      "Ostrze Równowagi": "Blade of Balance",
      "Ukazanie Killarasuna": "Killarasun's Revelation",
      "Szaty Płynnego Ognia": "Garment of Molten Flame",
      "Pancerz Vorlingów": "Vorling Armor",
      "Dusza Świata": "World's Soul",
      "Oko Bogów": "Eye of Gods",
      "Pancerz Nieśmiertelności": "Armor of Immortality",
      "Pancerz Żywiołów": "Armor of the Elements",
      "Pocałunek Miliona Gwiazd": "The Kiss of a Million Stars",
      "Zasłona Śmierci": "Veil of Death",
      //properties
      "Dodatkowe obrażenia od ognia": "Additional damage from fire",
      "Dodatkowe obrażenia od energii": "Additional damage from energy",
      "Dodatkowe obrażenia od zimna": "Additional damage from cold",
      "Dodatkowe PA": "Additional AP",
      "Obrona dystansowa": "Ranged defense",
      "Obrona przeciw urokom": "Mental defense",
      "Obrona wręcz": "Melee defense",
      "Oszukać przeznaczenie": "Cheat fate",
      "Regeneracja kondycji": "Stamina regen.",
      "Regeneracja many": "Mana regen.",
      "Wyssanie many": "Drain mana",
      "Zużycie kondycji": "Stamina usage",
      "Zużycie many": "Mana usage",
      "Odporność na korzenie": "Roots resistance",
      "Odporność na trafienie krytyczne": "Critical hits resistance",
      "Odporność na zamrożenie": "Freezing resistance",
      "Podwójne losowanie obrony": "Double defense roll",
      "Podwójne losowanie trafienia": "Double hit roll",
      "Przełamanie dymków": "Mental defense penetration",
      "Redukcja otrzymanych obrażeń": "Lowers damage taken",
      "Redukcja otrzymanych obrażeń biernych": "Passive damage reduction",
      "Redukcja otrzymanych obrażeń krytycznych":
        "Lowers critical damage taken",
      "Rozproszenie Holma": "Holm's Dispersion",
      "Skok Farida": "Farid's Interdimensional Jump",
      "Szansa na odczarowanie": "Dispel chance",
      "Szansa na podwójny atak": "Double attack chance",
      "Szansa na trafienie krytyczne": "Critical hit chance",
      "Modyfikator obrażeń fizycznych": "Physical damage",
      "Modyfikator obrażeń magicznych": "Magic damage",
      "Modyfikator trafień fizycznych": "Physical hit chance",
      "Modyfikator trafień dystansowych": "Ranged hit chance",
      "Modyfikator trafień magicznych": "Magic hit chance",
      //item search
      "no-items-found": "No items matching this name.",
      //regen tooltip
      "punktów na rundę": "points per round",
      //guild buffs
      "Dodaj buffy gildiowe": "Add guild buffs",
      manaUsage: "Mana usage",
      enduranceUsage: "Stamina usage",
      regeneration: "Regeneration",
      physRes: "Physical Res.",
      "Poziom świątyni": "Temple level",
      //skill tooltip
      broń: "weapon",
      Formuła: "Formula",
      Trudność: "Difficulty",
      "req-ap": "Req. AP",
      "know-req": "Req. knowledge for",
      "PA:": "AP",
      "current-kno": "Current knowledge",
      Osiągnięto: "Achieved",
      self: "Self",
      single: "Single",
      group: "Group",
      Cel: "Target",
      "Rodzaj umiejętności": "Ability type",
      attack: "Attack",
      debuff: "Debuff",
      buff: "Buff",
      "Strefa ataku": "Attack zone",
      melee: "Melee",
      ranged: "Ranged",
      mental: "Mental",
      "Atrybut do trafienia": "Hitting attribute",
      "Siła leczenia": "Healing power",
      "Modyfikator obrażeń": "Damage modifier",
      "Szansa trafienia": "Hit chance",
      "Czas trwania": "Duration",
      "avg-damage": "Avg. damage (not including resistances)",
      potrzeba: "still needed:",
      //skill effects
      "Obrażenia od Potężnego uderzenia": "Mighty Blow damage",
      "Ilość bloków": "Number of blocks",
      "Skuteczność bloku": "Block effectivness",
      "Koszt many na rundę": "Mana cost per round",
      "Skuteczność ochrony": "Protection chance",
      magicRes: "Magical Res.",
      "Zadawane obrażenia": "Damage dealt",
      "Skuteczność ataków": "Attacks hit chance",
      "Wzmaga krwawienie": "Increases bleeding",
      Krwawienie: "Bleeding",
      "Skuteczność obrony": "Defence success chance",
      "Liczba uników": "Number of dodges",
      "Skuteczność uników": "Chance to dodge",
      "Obrażenia od Uderzenia": "Bash damage",
      "Siła odczarowania": "Dispel power",
      Unieruchomienie: "Immobilization",
      "Szansa trafienia odłamkiem": "Fragment chance",
      "Szansa na efekt": "Effect chance",
      "Obrażenia od Precyzyjnego strzału": "Precise Shot damage",
      "Utrata kondycji": "Stamina loss",
      "Szansa na zatrucie": "Chance to poison",
      "Szansa na zabicie": "Chance to kill",
      "Suma rang pupili": "Total pet rank",
      "max-pets": "Max. pets",
      "Obrażenia od ognia": "Fire damage",
      "Obrażenia w HP": "Damage to HP",
      "Obrażenia w manę": "Damage to mana",
      "Reduktor straconej many": "Mana loss reduction",
      //lazy loading
      loading: "Loading data...",
      //routes
      home: "Home",
      calc: "Build Calculator",
      //home
      "intro-header-7": "What can I use The Pride of Taern Toolbox for?",
      "intro-desc-7": `The Pride of Taern Toolbox is a set of tools whose aim is to make playing The Pride of Taern easier. The main feature of the toolbox is the build calculator that will allow you to plan your character's development from level 1 to 140. It's an advanced tool that'll not only let you choose your euipment, upgrade it, add attribute points but also will calculate your damage and knowledge requirements (difficulty) of abilities. The build calculator includes all tables from Taern's wikipedia so that you have all the information you need in one place.`,
      "intro-desc-8": `If you don't feel like entering the whole build, you can also use the "Knowledge Calculator" tab that will allow you, without any unnecessary features, to calculate the amount of knowledge you need on a given character level to use your abilities with the right number of AP. The calculator also includes knowledge (difficulty) tables that will let you know how difficult each ability is.`,
      "intro-desc-9": `The "Legendary Items" tab contains a list of all items available to players in The Pride o Taern. You'll see all rares, psychorares and the most important of them all - epics. To make the list easier to use, you can filter the items list by attributes, search by name, check which bosses drop each piece of equipment and many other things. It's a great way to see all the items if you don't feel like creating a character build right now.`,
      //error
      error: "Error 404 - Page Not Found",
      "error-desc":
        "Who led you astray? There's nothing of interest in here. On the other hand, you may want to check out our awesome tools:",
      //know calc
      "know-calc": "Knowledge Calculator",
      "Wymagana wiedza dla poziomu": "Knowledge required at level",
      na: "for",
      "buff-desc":
        "To see the knowledge required for a given ability you need to level it up (using the plus and minus buttons). After you set the right level, the expanded ability tooltip will show you how much knowledge you need for various AP numbers.",
      "intro-header-1-know":
        "Why do I need The Pride of Taern Knowledge Calculator?",
      "intro-desc-1-know": `The Pride of Taern Knowledge Calculator will allow you, without any unnecessary features, to calculate the amount of knowledge you need on a given character level to use your abilities with the right number of AP. The calculator also includes knowledge (difficulty) tables that will let you know how difficult each ability is.`,
      "intro-header-3-know":
        "How do I use The Pride of Taern Knowledge Calculator?",
      "intro-desc-3-2-know": `After the above steps are complete, you will see the calculator with a list of abilities for the chosen class. Only abilities that require knowledge be used (those with difficulty) will be shown. To see the knowledge required for a given ability you need to level it up (using the plus and minus buttons). After you set the right level, the expanded ability tooltip will show you how much knowledge you need for various AP numbers.`,
      //items list
      "items-display": "Items List",
      Klasa: "Class",
      Rzadkość: "Rarity",
      Atrybut: "Attribute",
      Sortowanie: "Sort",
      reqLvl: "Req. level",
      Rodzaj: "Type",
      "items-intro": `This tab contains a list of all rare, psychorare, set and epic items available for players in The Pride of Taern. The items list can be filtered by using the filters below. You can also search for items by name and sort the list.`,
      "Obrazek": "Image",
      "Wymagania": "Requirements",
      "Statystyki": "Attributes",
      "Psychowłaściwości": "Psychoproperties",
      "items-display-description": "Here you will find a list of all rares, psychorares, epics and set items available to players in The Pride of Taern. Click to see them all!",
      //nowe
      "Ładunki": "Charges",
      "Amok": "Amok",
      "Tąpnięcie": "Earthquake",
      "Wirujące ostrze": "Dirty Axing",
      "Naturalna odporność": "Natural Resistance",
      "Obniżenie poziomu debuffów": "Lowers debuff levels",
      "Czas trwania unieruchomienia": "Immobilization duration"
    },
  },
};
