import { Item, RawItem } from "./models/item.model"

const itemsData: RawItem[] = [{
    name: "Derengil",
    type: "weapon",
    image: "[www.taernopedia.pl][649]Derengil.png",
    reqLvl: 15,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 49,
    strength: 6,
    agility: 3,
    power: 6,
    knowledge: 8,
    endurance: 40
  },
  {
    name: "Sturprang",
    type: "weapon",
    image: "[www.taernopedia.pl][242]Sturprang.png",
    reqLvl: 15,
    reqPow: 30,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 60,
    power: 3,
    knowledge: 6,
    mana: 40
  },
  {
    name: "Ayol",
    type: "weapon",
    image: "[www.taernopedia.pl][600]Ayol.png",
    reqLvl: 20,
    reqAgi: 12,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 67,
    strength: 8,
    mana: 40
  },
  {
    name: "Isverd",
    type: "weapon",
    image: "Isverd.png",
    reqLvl: 34,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 91,
    strength: 10,
    hp: 100,
    endurance: 30
  },
  {
    name: "Rolrak",
    type: "weapon",
    image: "[www.taernopedia.pl][28]Rolrak.png",
    reqLvl: 27,
    reqAgi: 50,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 88,
    strength: 6,
    agility: 9
  },
  {
    name: "Tasak",
    type: "weapon",
    image: "[www.taernopedia.pl][291]Tasak.png",
    reqLvl: 19,
    reqStr: 45,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 82,
    strength: 10,
    hp: 90
  },
  {
    name: "Davgretor II",
    type: "weapon",
    image: "[www.taernopedia.pl][746]Davgretor20II.png",
    reqLvl: 32,
    reqStr: 50,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 132,
    strength: 10,
    agility: 2,
    hp: 100
  },
  {
    name: "Geomorph Core",
    type: "weapon",
    image: "[www.taernopedia.pl][751]Geomorph20Core.png",
    reqLvl: 32,
    reqKno: 50,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 101,
    power: 10,
    knowledge: 2,
    mana: 100
  },
  {
    name: "Piroklast",
    type: "weapon",
    image: "[www.taernopedia.pl][315]Piroklast.png",
    reqLvl: 32,
    reqStr: 35,
    reqAgi: 30,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 96,
    agility: 9,
    hp: 80,
    endurance: 60
  },
  {
    name: "Tężec",
    type: "weapon",
    image: "[www.taernopedia.pl][717]TC499C5BCec.png",
    reqLvl: 28,
    damageType: "Kłute",
    weaponType: "Jednoręczna",
    damage: 85,
    power: 4,
    knowledge: 8,
    mana: 80
  },
  {
    name: "Sidun",
    type: "weapon",
    image: "[www.taernopedia.pl][274]Sidun.png",
    reqLvl: 34,
    reqStr: 40,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 124,
    strength: 6,
    hp: 100,
    mana: 50,
    endurance: 50
  },
  {
    name: "Irkamale",
    type: "weapon",
    image: "[www.taernopedia.pl][774]Irkamale.png",
    reqLvl: 34,
    reqAgi: 25,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 117,
    strength: 2,
    agility: 8,
    hp: 120,
    endurance: 60
  },
  {
    name: "Smoczy Gnat",
    type: "weapon",
    image: "[www.taernopedia.pl][1]Smoczy20Gnat.png",
    reqLvl: 45,
    reqPow: 60,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 122,
    power: 10,
    hp: 70,
    mana: 70
  },
  {
    name: "Ognisty Młot",
    type: "weapon",
    image: "[www.taernopedia.pl][322]Ognisty20MC582ot.png",
    reqLvl: 60,
    reqStr: 80,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 150,
    strength: 22,
    agility: 10,
    hp: 20
  },
  {
    name: "Władca Losu",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][272]WC582adca20Losu.png",
    reqLvl: 75,
    reqPow: 80,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 112,
    power: 20,
    knowledge: 8,
    hp: 20,
    mana: 50,
    otherProperties: [["Modyfikator obrażeń magicznych", 6, 1]]
  },
  {
    name: "Egzekutor",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][237]Egzekutor.png",
    reqLvl: 75,
    reqPow: 80,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 112,
    power: 18,
    knowledge: 11,
    hp: 30,
    mana: 30,
    otherProperties: [["Szansa na trafienie krytyczne", 4, 1]]
  },
  {
    name: "Gniew Zapomnianych",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][119]Gniew20Zapomnianych.png",
    reqLvl: 75,
    reqPow: 80,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 112,
    power: 8,
    knowledge: 20,
    hp: 50,
    mana: 20,
    otherProperties: [["Szansa na podwójny atak", 6, 1]]
  },
  {
    name: "Virral",
    type: "weapon",
    image: "[www.taernopedia.pl][105]Virral.png",
    reqLvl: 62,
    reqAgi: 80,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 145,
    strength: 15,
    agility: 15,
    hp: 50
  },
  {
    name: "Urntsul",
    type: "weapon",
    image: "[www.taernopedia.pl][846]Urntsul.png",
    reqLvl: 63,
    reqPow: 80,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 100,
    power: 14,
    knowledge: 10,
    hp: 290,
    mana: 100,
    randomStats: true
  },
  {
    name: "Buoriany",
    type: "weapon",
    image: "[www.taernopedia.pl][692]Buoriany.png",
    reqLvl: 70,
    reqAgi: 90,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 160,
    strength: 10,
    agility: 9,
    hp: 50,
    mana: 80,
    endurance: 70
  },
  {
    name: "Lawina",
    type: "weapon",
    image: "[www.taernopedia.pl][129]Lawina.png",
    reqLvl: 70,
    reqStr: 90,
    damageType: "Obuchowe",
    weaponType: "Jednoręczna",
    damage: 140,
    strength: 13,
    agility: 8,
    hp: 150,
    mana: 20
  },
  {
    name: "Istav",
    type: "weapon",
    image: "[www.taernopedia.pl][883]Istav.png",
    reqLvl: 80,
    reqKno: 90,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 140,
    power: 15,
    knowledge: 14,
    hp: 120,
    mana: 130
  },
  {
    name: "Fanga",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][957]Fanga.png",
    reqLvl: 75,
    reqAgi: 80,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 145,
    agility: 12,
    hp: 60,
    mana: 60,
    otherProperties: [["Szansa na trafienie krytyczne", 7, 1]]
  },
  {
    name: "Otwieracz",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][700]Otwieracz.png",
    reqLvl: 75,
    reqStr: 80,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 130,
    strength: 7,
    agility: 7,
    hp: 50,
    endurance: 30,
    otherProperties: [["Szansa na trafienie krytyczne", 4, 1]],
    randomStats: true
  },
  {
    name: "Gjolmar",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][197]Gjolmar.png",
    reqLvl: 85,
    reqAgi: 90,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 160,
    strength: 10,
    agility: 30,
    hp: 100,
    otherProperties: [["Modyfikator obrażeń fizycznych", 7, 1]]
  },
  {
    name: "Batagur",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][820]Batagur.png",
    reqLvl: 85,
    reqStr: 95,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 180,
    strength: 25,
    hp: 50,
    endurance: 200,
    otherProperties: [["Dodatkowe obrażenia od zimna", 7, 1]]
  },
  {
    name: "Mallus Selenorum",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][986]Mallus20Selenorum.png",
    reqLvl: 115,
    reqStr: 115,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 160,
    strength: 17,
    agility: 13,
    hp: 230,
    endurance: 70,
    otherProperties: [["Szansa na podwójny atak", 2, 1], ["Dodatkowe obrażenia od energii", 2, 1]]
  },
  {
    name: "Taehal",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][433]Taehal.png",
    reqLvl: 115,
    reqAgi: 115,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 170,
    strength: 12,
    agility: 33,
    hp: 210,
    mana: 60,
    endurance: 130,
    otherProperties: [["Szansa na podwójny atak", 2, 1], ["Szansa na trafienie krytyczne", 2, 1]]
  },
  {
    name: "Szpony",
    type: "weapon",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][975]Szpony.png",
    reqLvl: 115,
    reqAgi: 115,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 180,
    strength: 18,
    agility: 27,
    hp: 230,
    mana: 80,
    endurance: 90,
    otherProperties: [["Szansa na podwójny atak", 2, 1], ["Dodatkowe obrażenia od zimna", 4, 1]]
  },
  {
    name: "Trójząb Admiralski",
    type: "weapon",
    image: "[www.taernopedia.pl][372]TrC3B3jzC485b20Admiralski.png",
    rarity: "Psychorare",
    reqLvl: 115,
    reqPow: 115,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 165,
    power: 28,
    knowledge: 15,
    hp: 120,
    mana: 50,
    otherProperties: [["Szansa na trafienie krytyczne", 4, 1], ["Modyfikator obrażeń magicznych", 5, 1]]
  },
  {
    name: "Ból",
    type: "weapon",
    image: "[www.taernopedia.pl][34]BC3B3l.png",
    rarity: "Psychorare",
    reqLvl: 115,
    reqPow: 120,
    reqKno: 120,
    damageType: "Kłute",
    weaponType: "Jednoręczna",
    damage: 165,
    power: 17,
    knowledge: 32,
    hp: 180,
    mana: 130,
    endurance: 50,
    otherProperties: [["Szansa na trafienie krytyczne", 2, 1], ["Szansa na podwójny atak", 2, 1]]
  },
  {
    name: "Cierń",
    type: "weapon",
    image: "[www.taernopedia.pl][491]CierC584.png",
    rarity: "Psychorare",
    reqLvl: 115,
    reqStr: 115,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 250,
    strength: 15,
    agility: 21,
    hp: 170,
    endurance: 70,
    otherProperties: [["Szansa na trafienie krytyczne", 4, 1], ["Dodatkowe obrażenia od energii", 5, 1]]
  },
  {
    name: "Attawa",
    type: "weapon",
    image: "[www.taernopedia.pl][335]Attawa.png",
    rarity: "Epik",
    class: "voodoo",
    reqLvl: 60,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 100,
    power: 10,
    knowledge: 20,
    hp: 50,
    mana: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Szansa na trafienie krytyczne", 9, 1], ["Modyfikator trafień magicznych", 20, 2]]
  },
  {
    name: "Żmij",
    type: "weapon",
    image: "[www.taernopedia.pl][695]C5BBmij.png",
    rarity: "Epik",
    class: "firemage",
    reqLvl: 60,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 100,
    power: 20,
    knowledge: 10,
    hp: 100,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Szansa na trafienie krytyczne", 9, 1], ["Szansa na podwójny atak", 10, 1]]
  },
  {
    name: "Latarnia Życia",
    type: "weapon",
    image: "[www.taernopedia.pl][762]Latarnia20C5BBycia.png",
    rarity: "Epik",
    class: "druid",
    reqLvl: 60,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 100,
    power: 10,
    knowledge: 20,
    hp: 50,
    mana: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Wyssanie many", 8, 1], ["Szansa na trafienie krytyczne", 9, 1]]
  },
  {
    name: "Imisindo",
    type: "weapon",
    image: "[www.taernopedia.pl][584]Imisindo.png",
    rarity: "Epik",
    class: "archer",
    reqLvl: 60,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 85,
    strength: 10,
    agility: 10,
    hp: 150,
    endurance: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Szansa na trafienie krytyczne", 9, 1], ["Modyfikator trafień dystansowych", 20, 2]]
  },
  {
    name: "Washi",
    type: "weapon",
    image: "[www.taernopedia.pl][457]Washi.png",
    rarity: "Epik",
    class: "sheed",
    reqLvl: 60,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 95,
    strength: 10,
    agility: 10,
    hp: 150,
    endurance: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Szansa na trafienie krytyczne", 9, 1], ["Modyfikator trafień fizycznych", 20, 2]]
  },
  {
    name: "Gorthdar",
    type: "weapon",
    image: "[www.taernopedia.pl][272]Gorthdar.png",
    rarity: "Epik",
    class: "barbarian",
    reqLvl: 60,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 110,
    strength: 15,
    agility: 10,
    hp: 100,
    endurance: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Dodatkowe obrażenia od ognia", 12, 1], ["Szansa na trafienie krytyczne", 9, 1]]
  },
  {
    name: "Allenor",
    type: "weapon",
    image: "[www.taernopedia.pl][377]Allenor.png",
    rarity: "Epik",
    class: "knight",
    reqLvl: 60,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 80,
    strength: 10,
    agility: 10,
    knowledge: 5,
    hp: 100,
    endurance: 50,
    otherProperties: [["Dodatkowe PA", 1, 0], ["Modyfikator obrażeń fizycznych", 12, 1], ["Szansa na trafienie krytyczne", 9, 1]]
  },
  {
    name: "Isthrimm II",
    type: "shield",
    image: "[www.taernopedia.pl][606]Isthrimm20II.png",
    reqLvl: 34,
    reqStr: 55,
    strength: 5,
    hp: 80,
    cutRes: 31,
    bluntRes: 30,
    pierceRes: 30,
    fireRes: 25,
    class: "knight"
  },
  {
    name: "Smocze Skrzydło",
    type: "shield",
    image: "[www.taernopedia.pl][0]Smocze20SkrzydC582o.png",
    reqLvl: 45,
    reqStr: 60,
    strength: 10,
    agility: 6,
    hp: 100,
    endurance: 60,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    class: "knight"
  },
  {
    name: "Kil",
    type: "shield",
    image: "[www.taernopedia.pl][849]Kil.png",
    reqLvl: 110,
    reqStr: 120,
    reqAgi: 110,
    strength: 15,
    agility: 20,
    hp: 270,
    mana: 80,
    endurance: 100,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    class: "knight"
  },
  {
    name: "Karapaks",
    type: "shield",
    image: "[www.taernopedia.pl][903]Karapaks.png",
    reqLvl: 85,
    reqStr: 90,
    reqAgi: 90,
    strength: 10,
    agility: 10,
    hp: 200,
    endurance: 100,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    class: "knight"
  },
  {
    name: "Martumal",
    type: "helmet",
    image: "[www.taernopedia.pl][433]Martumal.png",
    reqLvl: 20,
    reqPow: 35,
    reqKno: 20,
    power: 8,
    knowledge: 7,
    mana: 40,
    cutRes: 16,
    bluntRes: 16,
    pierceRes: 20,
    curseRes: 20
  },
  {
    name: "Grzebień",
    type: "helmet",
    image: "[www.taernopedia.pl][450]GrzebieC584.png",
    reqLvl: 28,
    reqStr: 30,
    strength: 4,
    agility: 4,
    hp: 30,
    endurance: 70,
    cutRes: 23,
    bluntRes: 24,
    pierceRes: 27,
    frostRes: 10,
    curseRes: 10
  },
  {
    name: "Ishelm",
    type: "helmet",
    image: "[www.taernopedia.pl][306]Ishelm.png",
    reqLvl: 34,
    strength: 6,
    agility: 10,
    hp: 80,
    cutRes: 29,
    bluntRes: 32,
    pierceRes: 31
  },
  {
    name: "Khalam",
    type: "helmet",
    image: "[www.taernopedia.pl][87]Khalam.png",
    reqLvl: 28,
    power: 8,
    knowledge: 8,
    mana: 60,
    cutRes: 27,
    bluntRes: 24,
    pierceRes: 24,
    curseRes: 20
  },
  {
    name: "Gathril",
    type: "helmet",
    image: "[www.taernopedia.pl][209]Gathril.png",
    reqLvl: 55,
    reqStr: 70,
    hp: 190,
    cutRes: 35,
    bluntRes: 37,
    pierceRes: 38,
    fireRes: 30,
    frostRes: 30,
    energyRes: 30,
    curseRes: 20
  },
  {
    name: "Czacha",
    type: "helmet",
    image: "[www.taernopedia.pl][525]Czacha.png",
    reqLvl: 57,
    reqPow: 70,
    power: 12,
    knowledge: 12,
    hp: 100,
    cutRes: 30,
    bluntRes: 30,
    pierceRes: 30
  },
  {
    name: "Ghaitarog",
    type: "helmet",
    image: "[www.taernopedia.pl][401]Ghaitarog.png",
    reqLvl: 60,
    reqStr: 80,
    strength: 7,
    agility: 9,
    hp: 100,
    endurance: 100,
    cutRes: 32,
    bluntRes: 29,
    pierceRes: 32
  },
  {
    name: "Sigil",
    type: "helmet",
    image: "[www.taernopedia.pl][421]Sigil.png",
    reqLvl: 75,
    reqKno: 90,
    power: 10,
    knowledge: 10,
    hp: 160,
    mana: 50,
    cutRes: 31,
    bluntRes: 31,
    pierceRes: 31,
    energyRes: 25,
    randomStats: true
  },
  {
    name: "Pysk",
    type: "helmet",
    image: "[www.taernopedia.pl][695]Pysk.png",
    reqLvl: 90,
    reqStr: 95,
    strength: 11,
    agility: 11,
    hp: 180,
    mana: 50,
    endurance: 100,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 35
  },
  {
    name: "Pamięć Morany",
    type: "helmet",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][503]PamiC499C48720Morany.png",
    reqLvl: 120,
    reqStr: 115,
    reqAgi: 120,
    strength: 23,
    agility: 33,
    hp: 270,
    mana: 20,
    endurance: 100,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    otherProperties: [["Modyfikator obrażeń fizycznych", 7, 1], ["Obrona przeciw urokom", 3, 2], ["Odporność na zamrożenie", 10, 2]]
  },
  {
    name: "Miłość Morany",
    type: "helmet",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][434]MiC582oC59BC48720Morany.png",
    reqLvl: 120,
    reqPow: 115,
    reqKno: 120,
    power: 23,
    knowledge: 28,
    hp: 310,
    mana: 130,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    otherProperties: [["Modyfikator obrażeń magicznych", 7 ,1], ["Obrona wręcz", 3, 2], ["Odporność na zamrożenie", 10, 2]]
  },
  {
    name: "Htagan",
    type: "helmet",
    image: "[www.taernopedia.pl][1]Htagan.png",
    reqLvl: 100,
    reqPow: 105,
    power: 20,
    knowledge: 15,
    hp: 250,
    mana: 50,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 35,
    curseRes: 10
  },
  {
    name: "Bartaur",
    type: "armor",
    image: "[www.taernopedia.pl][826]Bartaur.png",
    reqLvl: 30,
    reqStr: 40,
    strength: 9,
    agility: 8,
    endurance: 50,
    cutRes: 30,
    bluntRes: 30,
    pierceRes: 30
  },
  {
    name: "Brunnle",
    type: "armor",
    image: "[www.taernopedia.pl][627]Brunnle.png",
    reqLvl: 40,
    reqStr: 40,
    strength: 6,
    agility: 6,
    hp: 60,
    endurance: 80,
    cutRes: 29,
    bluntRes: 31,
    pierceRes: 29
  },
  {
    name: "Pancerz Komandorski",
    type: "armor",
    image: "[www.taernopedia.pl][251]Pancerz20Komandorski.png",
    reqLvl: 45,
    power: 8,
    knowledge: 10,
    hp: 60,
    mana: 40,
    cutRes: 25,
    bluntRes: 27,
    pierceRes: 25
  },
  {
    name: "Virthil II",
    type: "armor",
    image: "[www.taernopedia.pl][19]Virthil20II.png",
    reqLvl: 55,
    reqStr: 80,
    agility: 10,
    hp: 100,
    endurance: 70,
    cutRes: 35,
    bluntRes: 37,
    pierceRes: 36,
    fireRes: 10,
    curseRes: 10
  },
  {
    name: "Diabolo",
    type: "armor",
    image: "[www.taernopedia.pl][163]Diabolo.png",
    reqLvl: 60,
    reqPow: 70,
    power: 19,
    knowledge: -5,
    hp: 150,
    mana: 50,
    cutRes: 25,
    bluntRes: 28,
    pierceRes: 30
  },
  {
    name: "Opoka Bogów",
    type: "armor",
    image: "[www.taernopedia.pl][673]Opoka20BogC3B3w.png",
    reqLvl: 55,
    reqStr: 40,
    strength: 8,
    agility: 8,
    hp: 80,
    endurance: 120,
    cutRes: 37,
    bluntRes: 34,
    pierceRes: 38
  },
  {
    name: "Nadzieja Pokoleń",
    type: "armor",
    image: "[www.taernopedia.pl][499]Nadzieja20PokoleC584.png",
    reqLvl: 80,
    reqPow: 80,
    power: 13,
    knowledge: 20,
    hp: 120,
    mana: 60,
    cutRes: 32,
    bluntRes: 35,
    pierceRes: 32,
    randomStats: true
  },
  {
    name: "Harttraum",
    type: "armor",
    image: "[www.taernopedia.pl][63]Harttraum.png",
    reqLvl: 75,
    reqAgi: 90,
    strength: 16,
    agility: 21,
    hp: 120,
    endurance: 30,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 35,
    randomStats: true
  },
  {
    name: "Vorleah",
    type: "armor",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][388]Vorleah.png",
    reqLvl: 95,
    reqKno: 100,
    power: 20,
    knowledge: 15,
    hp: 220,
    mana: 80,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 35,
    frostRes: 15,
    otherProperties: [["Skok Farida", 4, 1]]
  },
  {
    name: "Zalla",
    type: "armor",
    rarity: "Psychorare",
    image: "Zalla.png",
    reqLvl: 135,
    reqPow: 50,
    reqKno: 50,
    power: 22,
    knowledge: 37,
    hp: 280,
    mana: 100,
    endurance: 30,
    cutRes: 45,
    bluntRes: 45,
    pierceRes: 45,
    curseRes: 20,
    fireRes: 20,
    energyRes: 20,
    frostRes: 20,
    otherProperties: [["Podwójne losowanie obrony", 2, 2], ["Redukcja otrzymanych obrażeń", 2, 1], ["Obrona wręcz", 2, 2]]
  },
  {
    name: "Salmurn",
    type: "armor",
    rarity: "Psychorare",
    image: "Salmurn.png",
    reqLvl: 135,
    reqStr: 50,
    reqAgi: 50,
    strength: 25,
    agility: 34,
    hp: 230,
    endurance: 100,
    mana: 80,
    cutRes: 50,
    bluntRes: 50,
    pierceRes: 50,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    otherProperties: [["Podwójne losowanie obrony", 2, 2], ["Redukcja otrzymanych obrażeń", 2, 1], ["Obrona przeciw urokom", 2, 2]]
  },
  {
    name: "Dmorlung",
    type: "armor",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][932]Dmorlung.png",
    reqLvl: 95,
    reqStr: 100,
    strength: 18,
    agility: 18,
    hp: 150,
    endurance: 120,
    mana: 20,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    frostRes: 15,
    otherProperties: [["Rozproszenie Holma", 6, 1]]
  },
  {
    name: "Angwallion",
    type: "cape",
    image: "[www.taernopedia.pl][970]Angwallion.png",
    reqLvl: 100,
    reqAgi: 105,
    agility: 30,
    hp: 100,
    mana: 100,
    endurance: 100,
    curseRes: 10
  },
  {
    name: "Dracorporis",
    type: "cape",
    image: "[www.taernopedia.pl][168]Dracorporis.png",
    reqLvl: 85,
    reqPow: 90,
    power: 20,
    knowledge: 20,
    hp: 100,
    mana: 50
  },
  {
    name: "Nurthil",
    type: "cape",
    image: "[www.taernopedia.pl][65]Nurthil.png",
    reqLvl: 55,
    reqPow: 90,
    power: 19,
    knowledge: 14,
    hp: -50,
    fireRes: 15
  },
  {
    name: "Debba",
    type: "cape",
    image: "[www.taernopedia.pl][535]Debba.png",
    reqLvl: 61,
    agility: 20,
    hp: 100,
    endurance: 60
  },
  {
    name: "Xenothor",
    type: "cape",
    image: "[www.taernopedia.pl][157]Xenothor.png",
    reqLvl: 64,
    reqAgi: 80,
    strength: 10,
    agility: 18,
    hp: 80,
    endurance: -30,
    fireRes: 15
  },
  {
    name: "Bryza",
    type: "cape",
    image: "[www.taernopedia.pl][911]Bryza.png",
    reqLvl: 45,
    strength: 7,
    agility: 9,
    hp: 90,
    endurance: 30
  },
  {
    name: "Powrót Ivravula",
    type: "cape",
    image: "[www.taernopedia.pl][670]PowrC3B3t20Ivravula.png",
    reqLvl: 80,
    reqStr: 80,
    strength: 14,
    agility: 23,
    hp: 50,
    mana: 20,
    fireRes: 15,
    frostRes: 15,
    randomStats: true
  },
  {
    name: "Tsunami II v1",
    type: "cape",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][61]Tsunami20II.png",
    reqLvl: 85,
    reqPow: 90,
    reqKno: 90,
    power: 8,
    knowledge: 16,
    hp: 100,
    otherProperties: [["Odporność na trafienie krytyczne", 0, 1], ["Modyfikator trafień magicznych", 4, 2]]
  },
  {
    name: "Tsunami II v2",
    type: "cape",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][61]Tsunami20II.png",
    reqLvl: 85,
    reqPow: 90,
    reqKno: 90,
    power: 8,
    knowledge: 16,
    hp: 100,
    otherProperties: [["Odporność na trafienie krytyczne", 0, 1], ["Modyfikator trafień dystansowych", 4, 2]]
  },
  {
    name: "Cień Tarula",
    type: "cape",
    rarity: "Psychorare",
    image: "Cień_Tarula.png",
    reqLvl: 130,
    reqPow: 130,
    power: 26,
    knowledge: 34,
    hp: 300,
    mana: 80,
    endurance: 20,
    otherProperties: [["Podwójne losowanie trafienia", 4, 1], ["Redukcja otrzymanych obrażeń krytycznych", 7, 4], ["Zużycie many", -6, -2]]
  },
  {
    name: "Admiralski gronostaj",
    type: "cape",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][869]Admiralski20Gronostaj.png",
    reqLvl: 115,
    reqStr: 100,
    strength: 15,
    agility: 32,
    hp: 170,
    mana: 80,
    endurance: 130,
    otherProperties: [["Podwójne losowanie trafienia", 2, 1], ["Szansa na odczarowanie", 7, 1]]
  },
  {
    name: "Hańba Seleny",
    type: "cape",
    image: "[www.taernopedia.pl][5]HaC584ba20Seleny.png",
    reqLvl: 115,
    reqPow: 100,
    power: 12,
    knowledge: 20,
    hp: 250,
    mana: 180,
    endurance: 20,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    randomStats: true
  },
  {
    name: "Erbaile",
    type: "pants",
    image: "[www.taernopedia.pl][942]Erbaile.png",
    reqLvl: 115,
    reqStr: 100,
    strength: 11,
    agility: 29,
    hp: 220,
    endurance: 130,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    randomStats: true
  },
  {
    name: "Varrvy",
    type: "pants",
    image: "[www.taernopedia.pl][916]Varrvy.png",
    reqLvl: 85,
    strength: 20,
    agility: 15,
    hp: 100,
    endurance: 100,
    cutRes: 38,
    bluntRes: 39,
    pierceRes: 39
  },
  {
    name: "Gnaty Reffa",
    type: "armor",
    rarity: "Set",
    set: "Resztki Reffa",
    image: "[www.taernopedia.pl][668]Gnaty20Reffa.png",
    reqLvl: 22,
    agility: 5,
    endurance: 70,
    cutRes: 23,
    bluntRes: 22,
    pierceRes: 20,
    energyRes: 15
  },
  {
    name: "Łeb Reffa",
    type: "helmet",
    rarity: "Set",
    set: "Resztki Reffa",
    image: "[www.taernopedia.pl][205]C581eb20Reffa.png",
    reqLvl: 22,
    strength: 4,
    agility: 4,
    hp: 40,
    endurance: 40,
    cutRes: 18,
    bluntRes: 19,
    pierceRes: 20
  },
  {
    name: "Kulosy Reffa",
    type: "pants",
    rarity: "Set",
    set: "Resztki Reffa",
    image: "[www.taernopedia.pl][196]Kulosy20Reffa.png",
    reqLvl: 22,
    strength: 5,
    hp: 70,
    cutRes: 22,
    bluntRes: 22,
    pierceRes: 22,
    fireRes: 15
  },
  {
    name: "Rękawice Oddalenia",
    type: "gloves",
    rarity: "Set",
    set: "Okowy Snu",
    image: "[www.taernopedia.pl][535]RC499kawice20Oddalenia.png",
    reqLvl: 22,
    power: 7,
    knowledge: 5,
    energyRes: 15
  },
  {
    name: "Buty Wymiarów",
    type: "boots",
    rarity: "Set",
    set: "Okowy Snu",
    image: "[www.taernopedia.pl][574]Buty20WymiarC3B3w.png",
    reqLvl: 22,
    power: 6,
    knowledge: 6,
    hp: 60,
    cutRes: 21,
    bluntRes: 22,
    pierceRes: 20
  },
  {
    name: "Pas Ofiarowania",
    type: "belt",
    rarity: "Set",
    set: "Przebranie Ludobójcy",
    image: "[www.taernopedia.pl][700]Pas20ofiarowania.png",
    reqLvl: 40,
    power: 10,
    hp: 50,
    mana: 50,
  },
  {
    name: "Spodnie Hekatomby",
    type: "pants",
    rarity: "Set",
    set: "Przebranie Ludobójcy",
    image: "[www.taernopedia.pl][223]Spodnie20Hekatomby.png",
    reqLvl: 40,
    power: 4,
    knowledge: 7,
    hp: 80,
    mana: 40,
    cutRes: 25,
    bluntRes: 27,
    pierceRes: 26
  },
  {
    name: "Oblicze Sprawcy",
    type: "helmet",
    rarity: "Set",
    set: "Przebranie Ludobójcy",
    image: "[www.taernopedia.pl][780]Oblicze20Sprawcy.png",
    reqLvl: 40,
    power: 4,
    hp: 80,
    mana: 70,
    cutRes: 25,
    bluntRes: 26,
    pierceRes: 27,
    fireRes: 20
  },
  {
    name: "Całun Ludzkości",
    type: "cape",
    rarity: "Set",
    set: "Przebranie Ludobójcy",
    image: "[www.taernopedia.pl][239]CaC582un20LudzkoC59Bci.png",
    reqLvl: 40,
    power: 7,
    knowledge: 8,
    hp: 40
  },
  {
    name: "Szpony Szamana",
    type: "gloves",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][477]Szpony20Szamana.png",
    reqLvl: 40,
    strength: 6,
    agility: 10,
    endurance: 80,
    curseRes: 20
  },
  {
    name: "Orcza Zbroja",
    type: "armor",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][635]Orcza20Zbroja.png",
    reqLvl: 40,
    strength: 6,
    hp: 90,
    endurance: 80,
    cutRes: 31,
    bluntRes: 32,
    pierceRes: 30,
    frostRes: 20
  },
  {
    name: "Miecz Pinari",
    type: "weapon",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][742]Miecz20Pinari.png",
    reqLvl: 40,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 98,
    strength: 9,
    agility: 9,
    hp: 40
  },
  {
    name: "Łuk Pinari",
    type: "weapon",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][300]C581uk20Pinari.png",
    reqLvl: 40,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 106,
    strength: 9,
    agility: 9,
    hp: 40
  },
  {
    name: "Kastet Pinari",
    type: "weapon",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][494]Kastet20Pinari.png",
    reqLvl: 40,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 126,
    strength: 9,
    agility: 9,
    hp: 40
  },
  {
    name: "Topór Pinari",
    type: "weapon",
    rarity: "Set",
    set: "Zbroja Pinari",
    image: "[www.taernopedia.pl][189]TopC3B3r20Pinari.png",
    reqLvl: 40,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 135,
    strength: 9,
    agility: 9,
    hp: 40
  },
  {
    name: "Kij Huraganu",
    type: "weapon",
    rarity: "Set",
    class: "druid",
    set: "Gniew Żywiołów",
    image: "[www.taernopedia.pl][565]Kij20Huraganu.png",
    reqLvl: 50,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 126,
    power: -5,
    knowledge: 5,
    hp: 60,
    mana: 170
  },
  {
    name: "Laska Killarasuna",
    type: "weapon",
    rarity: "Set",
    class: "voodoo",
    set: "Ukazanie Killarasuna",
    image: "Killarasun's_Staff.png",
    reqLvl: 50,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 98,
    power: -5,
    knowledge: 5,
    hp: 70,
    mana: 200
  },
  {
    name: "Laska Płomieni",
    type: "weapon",
    rarity: "Set",
    class: "firemage",
    set: "Szaty Płynnego Ognia",
    image: "Flame_Staff.png",
    reqLvl: 50,
    damageType: "Obuchowe",
    weaponType: "Dwuręczna",
    damage: 98,
    knowledge: -5,
    hp: 100,
    mana: 220
  },
  {
    name: "Buty Popiołów",
    type: "boots",
    rarity: "Set",
    class: "firemage",
    set: "Szaty Płynnego Ognia",
    image: "Boots_of_Ashes.png",
    reqLvl: 50,
    hp: 150,
    mana: 70,
    cutRes: 33,
    bluntRes: 35,
    pierceRes: 34,
    fireRes: 25
  },
  {
    name: "Buty Vorlingów",
    type: "boots",
    rarity: "Set",
    class: "knight",
    set: "Pancerz Vorlingów",
    image: "Buty_vorlingow.png",
    reqLvl: 100,
    strength: 11,
    agility: 24,
    hp: 120,
    mana: 20,
    endurance: 110,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Spodnie Vorlingów",
    type: "pants",
    rarity: "Set",
    class: "knight",
    set: "Pancerz Vorlingów",
    image: "Spodnie_vorlingow.png",
    reqLvl: 100,
    strength: 13,
    agility: 17,
    hp: 180,
    mana: 30,
    endurance: 90,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Hełm Vorlingów",
    type: "helmet",
    rarity: "Set",
    class: "knight",
    set: "Pancerz Vorlingów",
    image: "Helm_vorlingow.png",
    reqLvl: 100,
    strength: 16,
    agility: 19,
    hp: 150,
    mana: 10,
    endurance: 90,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Zbroja Vorlingów",
    type: "armor",
    rarity: "Set",
    class: "knight",
    set: "Pancerz Vorlingów",
    image: "Zbroja_vorlingow.png",
    reqLvl: 100,
    strength: 14,
    agility: 21,
    hp: 120,
    mana: 20,
    endurance: 110,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Vahany",
    type: "boots",
    rarity: "Set",
    class: "sheed",
    set: "Dusza Świata",
    image: "ButyDusza.png",
    reqLvl: 100,
    strength: 12,
    agility: 33,
    hp: 70,
    mana: 10,
    endurance: 70,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Kirsile",
    type: "boots",
    rarity: "Set",
    class: "archer",
    set: "Oko Bogów",
    image: "ButyOko.png",
    reqLvl: 100,
    strength: 9,
    agility: 29,
    hp: 120,
    mana: 10,
    endurance: 90,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Tchnienie Przyszłości",
    type: "boots",
    rarity: "Set",
    class: "barbarian",
    set: "Pancerz Nieśmiertelności",
    image: "Butybarbarzynca.png",
    reqLvl: 100,
    strength: 18,
    agility: 17,
    hp: 160,
    mana: 10,
    endurance: 80,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Gromy",
    type: "boots",
    rarity: "Set",
    class: "druid",
    set: "Pancerz Żywiołów",
    image: "ButyPancerz.png",
    reqLvl: 100,
    power: 17,
    knowledge: 21,
    hp: 150,
    mana: 70,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Dreszcze",
    type: "boots",
    rarity: "Set",
    class: "voodoo",
    set: "Zasłona Śmierci",
    image: "ButyZasłona.png",
    reqLvl: 100,
    power: 12,
    knowledge: 33,
    hp: 30,
    mana: 120,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Szepty",
    type: "pants",
    rarity: "Set",
    class: "voodoo",
    set: "Zasłona Śmierci",
    image: "SpodnieZasłona.png",
    reqLvl: 100,
    power: 14,
    knowledge: 16,
    hp: 200,
    mana: 100,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Memento",
    type: "armor",
    rarity: "Set",
    class: "voodoo",
    set: "Zasłona Śmierci",
    image: "ZbrojaZasłona.png",
    reqLvl: 100,
    power: 13,
    knowledge: 24,
    hp: 150,
    mana: 80,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Zgon",
    type: "helmet",
    rarity: "Set",
    class: "voodoo",
    set: "Zasłona Śmierci",
    image: "HelmZasłona.png",
    reqLvl: 100,
    power: 11,
    knowledge: 23,
    hp: 150,
    mana: 110,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Agonia",
    type: "cape",
    rarity: "Set",
    class: "voodoo",
    set: "Zasłona Śmierci",
    image: "PelerynaZasłona.png",
    reqLvl: 100,
    power: 12,
    knowledge: 29,
    hp: 110,
    mana: 80,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Zgorzelniki",
    type: "boots",
    rarity: "Set",
    class: "firemage",
    set: "Pocałunek Miliona Gwiazd",
    image: "ButyPocałunek.png",
    reqLvl: 100,
    power: 17,
    knowledge: 18,
    hp: 160,
    mana: 80,
    endurance: 10,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Skry",
    type: "pants",
    rarity: "Set",
    class: "firemage",
    set: "Pocałunek Miliona Gwiazd",
    image: "SpodniePocałunek.png",
    reqLvl: 100,
    power: 14,
    knowledge: 21,
    hp: 150,
    mana: 90,
    endurance: 10,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Szeol",
    type: "helmet",
    rarity: "Set",
    class: "firemage",
    set: "Pocałunek Miliona Gwiazd",
    image: "HelmPocałunek.png",
    reqLvl: 100,
    power: 17,
    knowledge: 18,
    hp: 100,
    mana: 140,
    endurance: 10,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Żar",
    type: "armor",
    rarity: "Set",
    class: "firemage",
    set: "Pocałunek Miliona Gwiazd",
    image: "ZbrojaPocałunek.png",
    reqLvl: 100,
    power: 18,
    knowledge: 22,
    hp: 90,
    mana: 100,
    endurance: 10,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Słoneczny Wiatr",
    type: "cape",
    rarity: "Set",
    class: "firemage",
    set: "Pocałunek Miliona Gwiazd",
    image: "PelerynaPocałunek.png",
    reqLvl: 100,
    power: 23,
    knowledge: 17,
    hp: 130,
    mana: 60,
    endurance: 10,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Meandry",
    type: "pants",
    rarity: "Set",
    class: "druid",
    set: "Pancerz Żywiołów",
    image: "SpodniePancerz.png",
    reqLvl: 100,
    power: 19,
    knowledge: 16,
    hp: 130,
    mana: 120,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Wicher",
    type: "cape",
    rarity: "Set",
    class: "druid",
    set: "Pancerz Żywiołów",
    image: "PelerynaPancerz.png",
    reqLvl: 100,
    power: 14,
    knowledge: 23,
    hp: 130,
    mana: 100,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Korona Lasu",
    type: "helmet",
    rarity: "Set",
    class: "druid",
    set: "Pancerz Żywiołów",
    image: "HelmPancerz.png",
    reqLvl: 100,
    power: 14,
    knowledge: 20,
    hp: 150,
    mana: 110,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Wulkan",
    type: "armor",
    rarity: "Set",
    class: "druid",
    set: "Pancerz Żywiołów",
    image: "ZbrojaPancerz.png",
    reqLvl: 100,
    power: 19,
    knowledge: 18,
    hp: 140,
    mana: 90,
    cutRes: 32,
    bluntRes: 32,
    pierceRes: 32,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Spodnie Wieczności",
    type: "pants",
    rarity: "Set",
    class: "barbarian",
    set: "Pancerz Nieśmiertelności",
    image: "Spodnie.png",
    reqLvl: 100,
    strength: 16,
    agility: 19,
    hp: 150,
    mana: 10,
    endurance: 90,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Okrycie Wieczności",
    type: "cape",
    rarity: "Set",
    class: "barbarian",
    set: "Pancerz Nieśmiertelności",
    image: "Peleryna.png",
    reqLvl: 100,
    strength: 24,
    agility: 16,
    hp: 130,
    mana: 10,
    endurance: 60,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Korona Wiecznych Władców",
    type: "helmet",
    rarity: "Set",
    class: "barbarian",
    set: "Pancerz Nieśmiertelności",
    image: "Helm.png",
    reqLvl: 100,
    strength: 21,
    agility: 14,
    hp: 100,
    mana: 10,
    endurance: 140,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Starożytna Zbroja",
    type: "armor",
    rarity: "Set",
    class: "barbarian",
    set: "Pancerz Nieśmiertelności",
    image: "Zbroja.png",
    reqLvl: 100,
    strength: 18,
    agility: 18,
    hp: 130,
    mana: 10,
    endurance: 100,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Biesagi",
    type: "pants",
    rarity: "Set",
    class: "archer",
    set: "Oko Bogów",
    image: "SpodnieOko.png",
    reqLvl: 100,
    strength: 15,
    agility: 19,
    hp: 110,
    mana: 20,
    endurance: 130,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Korona Jastrzębia",
    type: "helmet",
    rarity: "Set",
    class: "archer",
    set: "Oko Bogów",
    image: "HelmOko.png",
    reqLvl: 100,
    strength: 12,
    agility: 21,
    hp: 130,
    mana: 40,
    endurance: 100,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Zbroja Strzelca",
    type: "armor",
    rarity: "Set",
    class: "archer",
    set: "Oko Bogów",
    image: "ZbrojaOko.png",
    reqLvl: 100,
    strength: 11,
    agility: 24,
    hp: 150,
    mana: 20,
    endurance: 80,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Kamuflaż",
    type: "cape",
    rarity: "Set",
    class: "archer",
    set: "Oko Bogów",
    image: "PelerynaOko.png",
    reqLvl: 100,
    strength: 14,
    agility: 25,
    hp: 100,
    mana: 30,
    endurance: 80,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Manpury",
    type: "pants",
    rarity: "Set",
    class: "sheed",
    set: "Dusza Świata",
    image: "SpodnieDusza.png",
    reqLvl: 100,
    strength: 14,
    agility: 17,
    hp: 140,
    mana: 20,
    endurance: 130,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Ajna",
    type: "helmet",
    rarity: "Set",
    class: "sheed",
    set: "Dusza Świata",
    image: "HelmDusza.png",
    reqLvl: 100,
    strength: 9,
    agility: 24,
    hp: 120,
    mana: 50,
    endurance: 100,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Anahata",
    type: "armor",
    rarity: "Set",
    class: "sheed",
    set: "Dusza Świata",
    image: "ZbrojaDusza.png",
    reqLvl: 100,
    strength: 14,
    agility: 23,
    hp: 150,
    mana: 20,
    endurance: 60,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Vaja",
    type: "cape",
    rarity: "Set",
    class: "sheed",
    set: "Dusza Świata",
    image: "PelerynaDusza.png",
    reqLvl: 100,
    strength: 11,
    agility: 29,
    hp: 100,
    mana: 30,
    endurance: 70,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Płaszcz Vorlingów",
    type: "cape",
    rarity: "Set",
    class: "knight",
    set: "Pancerz Vorlingów",
    image: "Peleryna_vorlingow.png",
    reqLvl: 100,
    strength: 14,
    agility: 16,
    hp: 200,
    mana: 20,
    endurance: 80,
    fireRes: 5,
    frostRes: 5,
    energyRes: 5,
    curseRes: 5
  },
  {
    name: "Rękawice Wulkanów",
    type: "gloves",
    rarity: "Set",
    class: "firemage",
    set: "Szaty Płynnego Ognia",
    image: "Volcano_Gloves.png",
    reqLvl: 50,
    power: 10,
    hp: 100,
    mana: 70
  },
  {
    name: "Pas Ognia",
    type: "belt",
    rarity: "Set",
    class: "firemage",
    set: "Szaty Płynnego Ognia",
    image: "Belt_of_Fire.png",
    reqLvl: 50,
    reqPow: 40,
    power: 10,
    knowledge: 10,
    mana: 70
  },
  {
    name: "Rękawice Killarasuna",
    type: "gloves",
    rarity: "Set",
    class: "voodoo",
    set: "Ukazanie Killarasuna",
    image: "Killarasun's_Gloves.png",
    reqLvl: 50,
    reqKno: 40,
    power: 10,
    knowledge: 10,
    hp: 70
  },
  {
    name: "Pas Killarasuna",
    type: "belt",
    rarity: "Set",
    class: "voodoo",
    set: "Ukazanie Killarasuna",
    image: "Killarasun's_Belt.png",
    reqLvl: 50,
    power: 10,
    knowledge: 10,
    mana: 70
  },
  {
    name: "Buty Killarasuna",
    type: "boots",
    rarity: "Set",
    class: "voodoo",
    set: "Ukazanie Killarasuna",
    image: "Killarasun's_Boots.png",
    reqLvl: 50,
    power: 10,
    knowledge: 10,
    hp: 20,
    cutRes: 33,
    bluntRes: 34,
    pierceRes: 36,
    fireRes: 25
  },
  {
    name: "Kąsacz",
    type: "weapon",
    rarity: "Set",
    class: "archer",
    set: "Żądło Bogów",
    image: "Biter.png",
    reqLvl: 50,
    damageType: "Kłute",
    weaponType: "Dwuręczna",
    damage: 132,
    strength: -5,
    agility: 12,
    hp: 150,
    endurance: 150
  },
  {
    name: "Miecz Boreasza",
    type: "weapon",
    rarity: "Set",
    class: "barbarian",
    set: "Ryk Północy",
    image: "Boreash's_Sword.png",
    reqLvl: 50,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 139,
    strength: 8,
    agility: -5,
    hp: 100,
    endurance: 140
  },
  {
    name: "Ostrze Haugura",
    type: "weapon",
    rarity: "Set",
    class: "knight",
    set: "Sztorm Haugura",
    image: "Haugur's_Blade.png",
    reqLvl: 50,
    damageType: "Sieczne",
    weaponType: "Jednoręczna",
    damage: 116,
    strength: -5,
    hp: 120,
    endurance: 200
  },
  {
    name: "Tekko Balansu",
    type: "weapon",
    rarity: "Set",
    class: "sheed",
    set: "Ostrze Równowagi",
    image: "Tekko_of_Balance.png",
    reqLvl: 50,
    reqAgi: 50,
    damageType: "Sieczne",
    weaponType: "Dwuręczna",
    damage: 139,
    strength: -5,
    hp: 100,
    endurance: 220
  },
  {
    name: "Hełm Harmonii",
    type: "helmet",
    rarity: "Set",
    class: "sheed",
    set: "Ostrze Równowagi",
    image: "Helmet_of_Harmony.png",
    reqLvl: 50,
    strength: 10,
    agility: 10,
    endurance: 20,
    cutRes: 33,
    bluntRes: 34,
    pierceRes: 35,
    curseRes: 25
  },
  {
    name: "Rękawice Czynu",
    type: "gloves",
    rarity: "Set",
    class: "sheed",
    set: "Ostrze Równowagi",
    image: "Gloves_of_Action.png",
    reqLvl: 50,
    strength: 5,
    agility: 17,
    energyRes: 25
  },
  {
    name: "Pas Równowagi",
    type: "belt",
    rarity: "Set",
    class: "sheed",
    set: "Ostrze Równowagi",
    image: "Belt_of_Equilibrium_.png",
    reqLvl: 50,
    agility: 10,
    hp: 120,
    mana: 50
  },
  {
    name: "Tarcza Haugura",
    type: "shield",
    rarity: "Set",
    class: "knight",
    set: "Sztorm Haugura",
    image: "Haugur's_Shield.png",
    reqLvl: 50,
    agility: 15,
    hp: 70,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    fireRes: 25
  },
  {
    name: "Hełm Haugura",
    type: "helmet",
    rarity: "Set",
    class: "knight",
    set: "Sztorm Haugura",
    image: "Haugur's_Helmet.png",
    reqLvl: 50,
    hp: 120,
    endurance: 100,
    cutRes: 30,
    bluntRes: 31,
    pierceRes: 32,
    curseRes: 25
  },
  {
    name: "Rękawice Haugura",
    type: "gloves",
    rarity: "Set",
    class: "knight",
    set: "Sztorm Haugura",
    image: "Haugur's_Gloves.png",
    reqLvl: 50,
    strength: 10,
    hp: 70,
    endurance: 100
  },
  {
    name: "Pas Luty",
    type: "belt",
    rarity: "Set",
    class: "barbarian",
    set: "Ryk Północy",
    image: "Cold_Belt.png",
    reqLvl: 50,
    strength: 10,
    agility: 10,
    endurance: 100,
    fireRes: 15
  },
  {
    name: "Rękawice Północy",
    type: "gloves",
    rarity: "Set",
    class: "barbarian",
    set: "Ryk Północy",
    image: "Gloves_of_the_North.png",
    reqLvl: 50,
    agility: 7,
    hp: 100,
    endurance: 100
  },
  {
    name: "Ryk Północy",
    type: "armor",
    rarity: "Set",
    class: "barbarian",
    set: "Ryk Północy",
    image: "Roar_of_the_North.png",
    reqLvl: 50,
    strength: 8,
    agility: 8,
    hp: 100,
    cutRes: 33,
    bluntRes: 32,
    pierceRes: 34,
  },
  {
    name: "Buty Północy",
    type: "boots",
    rarity: "Set",
    class: "barbarian",
    set: "Ryk Północy",
    image: "Boots_of_the_North.png",
    reqLvl: 50,
    strength: 15,
    agility: 7,
    cutRes: 31,
    bluntRes: 31,
    pierceRes: 31,
    energyRes: 25
  },
  {
    name: "Pas Trucizny",
    type: "belt",
    rarity: "Set",
    class: "archer",
    set: "Żądło Bogów",
    image: "Belt_of_Poison.png",
    reqLvl: 50,
    hp: 150,
    endurance: 120
  },
  {
    name: "Rękawice Toksyczne",
    type: "gloves",
    rarity: "Set",
    class: "archer",
    set: "Żądło Bogów",
    image: "Toxic_Gloves.png",
    reqLvl: 50,
    strength: 7,
    hp: 100,
    endurance: 100
  },
  {
    name: "Hełm Niełaski",
    type: "helmet",
    rarity: "Set",
    class: "archer",
    set: "Żądło Bogów",
    image: "Helmet_of_Disgrace.png",
    reqLvl: 50,
    strength: 10,
    agility: 12,
    cutRes: 33,
    bluntRes: 34,
    pierceRes: 35,
    energyRes: 25
  },
  {
    name: "Pas Powodzi",
    type: "belt",
    rarity: "Set",
    class: "druid",
    set: "Gniew Żywiołów",
    image: "[www.taernopedia.pl][979]Pas20Powodzi.png",
    reqLvl: 50,
    power: 10,
    knowledge: 10,
    mana: 70
  },
  {
    name: "Rękawice Suszy",
    type: "gloves",
    rarity: "Set",
    class: "druid",
    set: "Gniew Żywiołów",
    image: "[www.taernopedia.pl][968]RC499kawice20Suszy.png",
    reqLvl: 50,
    knowledge: 10,
    hp: 100,
    mana: 70
  },
  {
    name: "Buty Tąpnięcia",
    type: "boots",
    rarity: "Set",
    class: "druid",
    set: "Gniew Żywiołów",
    image: "[www.taernopedia.pl][903]Buty20TC485pniC499cia.png",
    reqLvl: 50,
    hp: 150,
    mana: 70,
    cutRes: 34,
    bluntRes: 33,
    pierceRes: 35,
    fireRes: 25
  },
  {
    name: "Szarfa Nieistnienia",
    type: "belt",
    rarity: "Set",
    set: "Okowy Snu",
    image: "[www.taernopedia.pl][142]Szarfa20Nieistnienia.png",
    reqLvl: 22,
    hp: 60,
    mana: 60,
    fireRes: 15
  },
  {
    name: "Obdartusy",
    type: "pants",
    image: "[www.taernopedia.pl][937]Obdartusy.png",
    reqLvl: 57,
    reqStr: 70,
    strength: 14,
    agility: 10,
    hp: 110,
    cutRes: 36,
    bluntRes: 32,
    pierceRes: 37
  },
  {
    name: "Wzorek",
    type: "pants",
    image: "[www.taernopedia.pl][526]Wzorek.png",
    reqLvl: 55,
    reqKno: 70,
    power: 12,
    knowledge: 12,
    hp: 80,
    mana: 20,
    cutRes: 30,
    bluntRes: 30,
    pierceRes: 30
  },
  {
    name: "Tirhel II",
    type: "pants",
    image: "[www.taernopedia.pl][614]Tirhel20II.png",
    reqLvl: 55,
    reqPow: 80,
    power: 6,
    knowledge: 10,
    hp: 110,
    cutRes: 36,
    bluntRes: 32,
    pierceRes: 34,
    fireRes: 10,
    curseRes: 10
  },
  {
    name: "Skiilfy",
    type: "pants",
    image: "[www.taernopedia.pl][325]Skiilfy.png",
    reqLvl: 85,
    reqPow: 90,
    reqKno: 90,
    power: 15,
    knowledge: 20,
    hp: 150,
    mana: 50,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 35
  },
  {
    name: "Aquariusy II v1",
    type: "pants",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][995]Aquariusy20II.png",
    reqLvl: 85,
    reqStr: 90,
    reqAgi: 90,
    strength: 10,
    agility: 18,
    endurance: 60,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    otherProperties: [["Odporność na trafienie krytyczne", 0, 1], ["Modyfikator trafień fizycznych", 4, 2]]
  },
  {
    name: "Aquariusy II v2",
    type: "pants",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][995]Aquariusy20II.png",
    reqLvl: 85,
    reqStr: 90,
    reqAgi: 90,
    strength: 10,
    agility: 18,
    endurance: 60,
    cutRes: 36,
    bluntRes: 36,
    pierceRes: 36,
    otherProperties: [["Odporność na trafienie krytyczne", 0, 1], ["Modyfikator trafień dystansowych", 4, 2]]
  },
  {
    name: "Ziraki",
    type: "pants",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][91]Ziraki.png",
    reqLvl: 130,
    reqStr: 130,
    strength: 27,
    agility: 33,
    hp: 280,
    endurance: 80,
    mana: 40,
    cutRes: 38,
    bluntRes: 38,
    pierceRes: 38,
    otherProperties: [["Podwójne losowanie trafienia", 4, 1], ["Redukcja otrzymanych obrażeń krytycznych", 7, 4], ["Zużycie kondycji", -6, -2]]
  },
  {
    name: "Temary II",
    type: "pants",
    rarity: "Psychorare",
    image: "Temary.png",
    reqLvl: 125,
    reqPow: 50,
    reqKno: 50,
    knowledge: 25,
    hp: 750,
    cutRes: 45,
    bluntRes: 45,
    pierceRes: 45,
    curseRes: 20,
    fireRes: 20,
    energyRes: 20,
    frostRes: 20,
    otherProperties: [["Redukcja otrzymanych obrażeń biernych", 1, 2], ["Szansa na odczarowanie", 1, 1]]
  },
  {
    name: "Udręki",
    type: "pants",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][131]UdrC499ki.png",
    reqLvl: 110,
    reqPow: 100,
    power: 19,
    knowledge: 30,
    hp: 250,
    mana: 110,
    cutRes: 39,
    bluntRes: 39,
    pierceRes: 39,
    otherProperties: [["Modyfikator obrażeń magicznych", 4, 1], ["Przełamanie odporności na urok", 4, 1]]
  },
  {
    name: "Arhauty II",
    type: "boots",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][361]Arhauty20II.png",
    reqLvl: 125,
    reqStr: 50,
    reqAgi: 50,
    agility: 25,
    hp: 750,
    cutRes: 50,
    bluntRes: 50,
    pierceRes: 50,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    otherProperties: [["Redukcja otrzymanych obrażeń biernych", 1, 2], ["Szansa na odczarowanie", 1, 1]]
  },
  {
    name: "Moczary",
    type: "boots",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][415]Moczary.png",
    reqLvl: 30,
    reqStr: 45,
    strength: 5,
    agility: 4,
    hp: 30,
    cutRes: 23,
    bluntRes: 22,
    pierceRes: 25,
    otherProperties: [["Modyfikator trafień fizycznych", 10, 2]]
  },
  {
    name: "Virveny",
    type: "boots",
    image: "[www.taernopedia.pl][403]Virveny.png",
    reqLvl: 75,
    reqStr: 90,
    reqAgi: 90,
    strength: 10,
    agility: 8,
    hp: 150,
    mana: 50,
    endurance: 150,
    cutRes: 37,
    bluntRes: 38,
    pierceRes: 36,
    fireRes: 15
  },
  {
    name: "Jeroszki",
    type: "boots",
    image: "[www.taernopedia.pl][260]Jeroszki.png",
    reqLvl: 34,
    reqStr: 40,
    strength: 4,
    hp: 120,
    endurance: 120,
    cutRes: 29,
    bluntRes: 30,
    pierceRes: 30,
    fireRes: 10
  },
  {
    name: "Tangnary",
    type: "boots",
    image: "[www.taernopedia.pl][91]Tangnary.png",
    reqLvl: 50,
    reqAgi: 70,
    strength: 11,
    agility: 11,
    hp: 50,
    endurance: 30,
    cutRes: 33,
    bluntRes: 27,
    pierceRes: 33,
    energyRes: 15,
    randomStats: true
  },
  {
    name: "Lysmary",
    type: "boots",
    image: "[www.taernopedia.pl][59]Lysmary.png",
    reqLvl: 34,
    reqKno: 40,
    power: 8,
    knowledge: 2,
    hp: 80,
    mana: 60,
    cutRes: 30,
    bluntRes: 29,
    pierceRes: 31
  },
  {
    name: "Czengsvesy",
    type: "boots",
    image: "[www.taernopedia.pl][692]Czengsvesy.png",
    reqLvl: 27,
    power: 6,
    knowledge: 9,
    mana: 50,
    cutRes: 17,
    bluntRes: 20,
    pierceRes: 20
  },
  {
    name: "Alendry",
    type: "boots",
    image: "[www.taernopedia.pl][511]Alendry.png",
    reqLvl: 110,
    reqPow: 100,
    power: 16,
    knowledge: 55,
    hp: 200,
    mana: 40,
    cutRes: 37,
    bluntRes: 37,
    pierceRes: 37,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    randomStats: true
  },
  {
    name: "Thorimmy",
    type: "boots",
    image: "[www.taernopedia.pl][837]Thorimmy.png",
    reqLvl: 72,
    reqKno: 90,
    power: 10,
    knowledge: 10,
    hp: 160,
    mana: 20,
    cutRes: 35,
    bluntRes: 35,
    pierceRes: 30,
    frostRes: 15
  },
  {
    name: "Aqueniry",
    type: "boots",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][856]Aqueniry.png",
    reqLvl: 85,
    reqPow: 85,
    reqKno: 85,
    power: 19,
    knowledge: 16,
    hp: 150,
    mana: 20,
    cutRes: 34,
    bluntRes: 37,
    pierceRes: 37,
    otherProperties: [["Zużycie many", 26, -2], ["Szansa na podwójny atak", 4, 1]]
  },
  {
    name: "Envile",
    type: "boots",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][637]Envile.png",
    reqLvl: 120,
    reqPow: 100,
    power: 25,
    knowledge: 28,
    hp: 160,
    mana: 110,
    cutRes: 30,
    bluntRes: 30,
    pierceRes: 30,
    otherProperties: [["Szansa na podwójny atak", 2, 1], ["Modyfikator obrażeń magicznych", 2, 1]]
  },
  {
    name: "Cierpiętniki",
    type: "boots",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][134]CierpiC499tniki.png",
    reqLvl: 110,
    reqStr: 100,
    strength: 22,
    agility: 28,
    hp: 240,
    endurance: 110,
    cutRes: 40,
    bluntRes: 40,
    pierceRes: 40,
    otherProperties: [["Modyfikator obrażeń fizycznych", 4, 1], ["Modyfikator trafień fizycznych", 10, 2]]
  },
  {
    name: "Objęcia Morany",
    type: "belt",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][202]ObjC499cia20Morany.png",
    reqLvl: 115,
    reqPow: 100,
    reqKno: 125,
    power: 22,
    knowledge: 29,
    hp: 230,
    mana: 160,
    otherProperties: [["Obrona wręcz", 5, 2], ["Obrona dystansowa", 3, 2], ["Obrona przeciw urokom", 1, 2]]
  },
  {
    name: "Exuvium",
    type: "belt",
    image: "[www.taernopedia.pl][199]Exuvium.png",
    reqLvl: 90,
    reqKno: 90,
    power: 5,
    knowledge: 35,
    hp: 100,
    mana: 50
  },
  {
    name: "Radius Electricum",
    type: "belt",
    image: "[www.taernopedia.pl][206]Radius20Electricum.png",
    reqLvl: 36,
    reqPow: 40,
    power: 10,
    knowledge: 6,
    mana: 70
  },
  {
    name: "Sentrion",
    type: "belt",
    image: "[www.taernopedia.pl][740]Sentrion.png",
    reqLvl: 64,
    reqPow: 90,
    power: -5,
    knowledge: 30,
    hp: 50,
    mana: 50
  },
  {
    name: "Anabolik",
    type: "belt",
    image: "[www.taernopedia.pl][309]Anabolik.png",
    reqLvl: 19,
    reqStr: 25,
    strength: 8,
    hp: 110
  },
  {
    name: "Dagorilm",
    type: "belt",
    image: "[www.taernopedia.pl][526]Dagorilm.png",
    reqLvl: 71,
    reqStr: 90,
    strength: 5,
    agility: 10,
    knowledge: 6,
    hp: 150,
    endurance: 40
  },
  {
    name: "Koriatula",
    type: "belt",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][773]Koriatula.png",
    reqLvl: 35,
    reqKno: 45,
    power: 5,
    knowledge: 3,
    hp: 60,
    otherProperties: [["Modyfikator trafień dystansowych", 10, 2]]
  },
  {
    name: "Promuris",
    type: "belt",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][873]Promuris.png",
    reqLvl: 35,
    reqAgi: 45,
    strength: 4,
    agility: 5,
    hp: 50,
    otherProperties: [["Odporność na korzenie", 10, 2], ["Modyfikator obrażeń fizycznych", 3, 1]]
  },
  {
    name: "Nurt",
    type: "belt",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][135]Nurt.png",
    reqLvl: 85,
    reqStr: 85,
    reqAgi: 85,
    strength: 17,
    agility: 15,
    hp: 160,
    endurance: 40,
    otherProperties: [["Zużycie kondycji", 26, -2], ["Szansa na podwójny atak", 4, 1]]
  },
  {
    name: "Nienawiść Draugula",
    type: "belt",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][67]NienawiC59BC48720Draugula.png",
    reqLvl: 120,
    reqStr: 100,
    strength: 21,
    agility: 32,
    hp: 170,
    mana: 50,
    endurance: 50,
    otherProperties: [["Szansa na podwójny atak", 2, 1], ["Modyfikator obrażeń fizycznych", 2, 1]]
  },
  {
    name: "Groza Seleny",
    type: "belt",
    image: "[www.taernopedia.pl][281]Groza20Seleny.png",
    reqLvl: 110,
    reqStr: 100,
    strength: 16,
    agility: 55,
    hp: 200,
    endurance: 40,
    curseRes: 15,
    fireRes: 15,
    energyRes: 15,
    frostRes: 15,
    randomStats: true
  },
  {
    name: "Disolver",
    type: "ring",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][959]Disolver.png",
    reqLvl: 100,
    frostRes: 45,
    curseRes: 45,
    otherProperties: [["Odporność na zamrożenie", 20, 2]]
  },
  {
    name: "Allakaja Many",
    type: "ring",
    set: "Światła Tellabagu",
    rarity: "Set",
    image: "[www.taernopedia.pl][788]Allakaja20Many.png",
    reqLvl: 35,
    mana: 80,
    endurance: 80,
    energyRes: 15
  },
  {
    name: "Allakaja Kondycji",
    type: "ring",
    set: "Światła Tellabagu",
    rarity: "Set",
    image: "[www.taernopedia.pl][150]Allakaja20Kondycji.png",
    reqLvl: 35,
    hp: 140,
    fireRes: 15
  },
  {
    name: "Gwiazda Tellabagu",
    type: "neck",
    set: "Światła Tellabagu",
    rarity: "Set",
    image: "[www.taernopedia.pl][84]Gwiazda20Tellabagu.png",
    reqLvl: 35,
    agility: 10,
    knowledge: 10,
    curseRes: 15
  },
  {
    name: "Zagłada Ludów",
    type: "ring",
    image: "[www.taernopedia.pl][474]ZagC582ada20LudC3B3w.png",
    reqLvl: 110,
    reqStr: 100,
    strength: 30,
    agility: 30,
    hp: 100,
    mana: 20,
    endurance: 30,
    randomStats: true
  },
  {
    name: "Nit",
    type: "ring",
    image: "[www.taernopedia.pl][63]Nit.png",
    reqLvl: 50,
    strength: 12,
    agility: 15,
    hp: 30
  },
  {
    name: "Karlder",
    type: "ring",
    image: "[www.taernopedia.pl][928]Karlder.png",
    reqLvl: 38,
    reqStr: 50,
    strength: 10,
    agility: 10,
    hp: 60
  },
  {
    name: "Fiskorl II",
    type: "ring",
    image: "[www.taernopedia.pl][159]Fiskorl20II.png",
    reqLvl: 27,
    reqStr: 40,
    strength: 9,
    agility: 9,
    frostRes: 20,
    curseRes: 20
  },
  {
    name: "Navigon",
    type: "ring",
    image: "[www.taernopedia.pl][660]Navigon.png",
    reqLvl: 50,
    power: 12,
    knowledge: 15,
    hp: 30
  },
  {
    name: "Fulgur II",
    type: "ring",
    image: "[www.taernopedia.pl][293]Fulgur20II.png",
    reqLvl: 36,
    reqPow: 50,
    power: 4,
    knowledge: 8,
    hp: 100,
    frostRes: 30
  },
  {
    name: "Basileus",
    type: "ring",
    image: "[www.taernopedia.pl][290]Basileus.png",
    reqLvl: 28,
    reqPow: 40,
    power: 9,
    knowledge: 9,
    frostRes: 20,
    curseRes: 20
  },
  {
    name: "Uguns",
    type: "ring",
    image: "[www.taernopedia.pl][546]Uguns.png",
    reqLvl: 32,
    hp: 140,
    mana: 60,
    endurance: 60,
    fireRes: 20
  },
  {
    name: "Arcanscape II",
    type: "ring",
    image: "[www.taernopedia.pl][902]Arcanscape20II.png",
    reqLvl: 20,
    hp: 80,
    mana: 30,
    endurance: 30,
    curseRes: 40
  },
  {
    name: "Griv",
    type: "ring",
    image: "[www.taernopedia.pl][460]Griv.png",
    reqLvl: 75,
    hp: 400,
    mana: 50,
    endurance: 50
  },
  {
    name: "Balast",
    type: "ring",
    image: "[www.taernopedia.pl][959]Balast.png",
    reqLvl: 50,
    hp: 240,
    mana: 30,
    endurance: 30,
    energyRes: 20
  },
  {
    name: "Mauremys",
    type: "ring",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][128]Mauremys.png",
    reqLvl: 85,
    reqPow: 90,
    power: 20,
    knowledge: 20,
    hp: 100,
    otherProperties: [["Zużycie many", -5, -2]]
  },
  {
    name: "Skogan",
    type: "ring",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][393]Skogan.png",
    reqLvl: 85,
    reqStr: 90,
    strength: 20,
    agility: 20,
    hp: 80,
    mana: 20,
    otherProperties: [["Zużycie kondycji", -5, -2]]
  },
  {
    name: "Przysięga Draugula",
    type: "ring",
    image: "[www.taernopedia.pl][409]PrzysiC499ga20Draugula.png",
    reqLvl: 110,
    reqPow: 100,
    power: 30,
    knowledge: 30,
    hp: 100,
    mana: 30,
    endurance: 20,
    randomStats: true
  },
  {
    name: "Ortasis",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][392]Ortasis.png",
    reqLvl: 130,
    power: 20,
    knowledge: 20,
    hp: 100,
    mana: 70,
    endurance: 30,
    fireRes: 10,
    energyRes: 30,
    otherProperties: [["Modyfikator obrażeń magicznych", 3, 1], ["Szansa na odczarowanie", 3, 1], ["Redukcja otrzymanych obrażeń biernych", 6, 2]]
  },
  {
    name: "Vogurun",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][567]Vogurun.png",
    reqLvl: 75,
    reqKno: 70,
    power: 10,
    knowledge: 10,
    otherProperties: [["Modyfikator obrażeń magicznych", 10, 1]]
  },
  {
    name: "Ostolbin",
    type: "neck",
    image: "[www.taernopedia.pl][853]Ostolbin.png",
    reqLvl: 34,
    reqPow: 40,
    power: 6,
    knowledge: 6,
    hp: 60,
    mana: 100
  },
  {
    name: "Ibura",
    type: "neck",
    image: "[www.taernopedia.pl][945]Ibura.png",
    power: 1,
    knowledge: 2,
    hp: 30,
    fireRes: 10,
    curseRes: 10
  },
  {
    name: "Hadras",
    type: "neck",
    image: "[www.taernopedia.pl][312]Hadras.png",
    strength: 1,
    agility: 2,
    hp: 30,
    fireRes: 10,
    curseRes: 10
  },
  {
    name: "Maiarot",
    type: "neck",
    image: "[www.taernopedia.pl][184]Maiarot.png",
    reqLvl: 15,
    hp: 100
  },
  {
    name: "Markahn",
    type: "neck",
    image: "[www.taernopedia.pl][823]Markahn.png",
    reqLvl: 32,
    agility: 3,
    knowledge: 3,
    hp: 100,
    fireRes: 38,
    curseRes: 38
  },
  {
    name: "Sphaera",
    type: "neck",
    image: "[www.taernopedia.pl][653]Sphaera.png",
    reqLvl: 36,
    reqStr: 40,
    strength: 8,
    agility: 8,
    endurance: 80
  },
  {
    name: "Zemsta Ivravula",
    type: "neck",
    image: "[www.taernopedia.pl][626]Zemsta20Ivravula.png",
    reqLvl: 70,
    hp: 400,
    fireRes: 10,
    frostRes: 10,
    energyRes: 10,
    curseRes: 10,
    randomStats: true
  },
  {
    name: "Caratris",
    type: "neck",
    image: "[www.taernopedia.pl][479]Caratris.png",
    reqLvl: 45,
    hp: 40,
    fireRes: 40,
    frostRes: 40,
    energyRes: 40,
    curseRes: 40
  },
  {
    name: "Obroża Władcy",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][819]ObroC5BCa20WC582adcy.png",
    reqLvl: 35,
    reqKno: 45,
    power: 7,
    hp: 80,
    otherProperties: [["Regeneracja many", 0.5, 0.3], ["Modyfikator obrażeń magicznych", 3, 1]]
  },
  {
    name: "Valazan",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][78]Valazan.png",
    reqLvl: 50,
    power: 9,
    knowledge: 9,
    hp: 50,
    mana: 40,
    otherProperties: [["Zużycie many", -2, -2]],
    randomStats: true
  },
  {
    name: "Danthum",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][980]Danthum.png",
    reqLvl: 50,
    strength: 9,
    agility: 9,
    hp: 50,
    endurance: 40,
    otherProperties: [["Zużycie kondycji", -2, -2]],
    randomStats: true
  },
  {
    name: "Yurugu",
    type: "neck",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][602]Yurugu.png",
    reqLvl: 75,
    reqStr: 80,
    strength: 10,
    agility: 10,
    otherProperties: [["Modyfikator obrażeń fizycznych", 10, 1]]
  },
  {
    name: "Dorbis",
    type: "neck",
    rarity: "Psychorare",
    image: "Dorbis.png",
    reqLvl: 130,
    strength: 20,
    agility: 20,
    hp: 100,
    mana: 30,
    endurance: 70,
    fireRes: 10,
    energyRes: 30,
    otherProperties: [["Modyfikator obrażeń fizycznych", 3, 1], ["Szansa na odczarowanie", 3, 1], ["Redukcja otrzymanych obrażeń biernych", 6, 2]]
  },
  {
    name: "Serce Seleny",
    type: "neck",
    image: "[www.taernopedia.pl][369]Serce20Seleny.png",
    reqLvl: 115,
    hp: 200,
    mana: 50,
    endurance: 50,
    curseRes: 50,
    fireRes: 50,
    energyRes: 50,
    frostRes: 50,
    randomStats: true
  },
  {
    name: "Aeterus Passio",
    type: "gloves",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][750]Aeterus20Passio.png",
    reqLvl: 115,
    reqStr: 110,
    reqAgi: 125,
    strength: 30,
    agility: 30,
    hp: 250,
    endurance: 50,
    otherProperties: [["Obrona wręcz", 1, 2], ["Obrona dystansowa", 3, 2], ["Obrona przeciw urokom", 5, 2]]
  },
  {
    name: "Biltabandury",
    type: "gloves",
    image: "[www.taernopedia.pl][982]Biltabandury.png",
    reqLvl: 63,
    reqStr: 80,
    strength: 2,
    agility: 12,
    hp: 100,
    endurance: 100
  },
  {
    name: "Zadry",
    type: "gloves",
    image: "[www.taernopedia.pl][843]Zadry.png",
    reqLvl: 75,
    reqStr: 70,
    strength: 15,
    agility: 18,
    hp: 180,
    endurance: 40
  },
  {
    name: "Gest Władcy",
    type: "gloves",
    image: "[www.taernopedia.pl][368]Gest20WC582adcy.png",
    reqLvl: 32,
    reqStr: 40,
    strength: 12,
    agility: 5,
    hp: 40,
    endurance: 20
  },
  {
    name: "Brassary II",
    type: "gloves",
    image: "[www.taernopedia.pl][856]Brassary20II.png",
    reqLvl: 32,
    power: 10,
    knowledge: 5,
    mana: 60,
    fireRes: 20,
    frostRes: 20
  },
  {
    name: "Vaekany",
    type: "gloves",
    image: "[www.taernopedia.pl][547]Vaekany.png",
    reqLvl: 65,
    reqPow: 90,
    power: 10,
    knowledge: 7,
    hp: 20,
    mana: 180
  },
  {
    name: "Pazury",
    type: "gloves",
    image: "[www.taernopedia.pl][16]Pazury.png",
    reqLvl: 90,
    reqKno: 95,
    power: 15,
    knowledge: 10,
    hp: 120,
    mana: 180
  },
  {
    name: "Fraxy",
    type: "gloves",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][552]Fraxy.png",
    reqLvl: 30,
    reqPow: 45,
    power: 3,
    knowledge: 2,
    hp: 40,
    mana: 10,
    otherProperties: [["Modyfikator trafień magicznych", 10, 2]]
  },
  {
    name: "Skry Utoru",
    type: "gloves",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][45]Skry20Utoru.png",
    reqLvl: 115,
    reqPow: 100,
    power: 23,
    knowledge: 27,
    hp: 190,
    mana: 160,
    otherProperties: [["Modyfikator trafień dystansowych", 10, 2], ["Szansa na odczarowanie", 7, 1]]
  },
  {
    name: "Szpony Seimhi",
    type: "gloves",
    rarity: "Psychorare",
    image: "[www.taernopedia.pl][348]Szpony20Seimhi.png",
    reqLvl: 115,
    reqPow: 100,
    power: 23,
    knowledge: 27,
    hp: 190,
    mana: 160,
    otherProperties: [["Modyfikator trafień magicznych", 10, 2], ["Szansa na odczarowanie", 7, 1]]
  },
  {
    name: "Ariarchy",
    type: "shield",
    rarity: "Psychorare",
    image: "mag120_att.png",
    reqLvl: 120,
    reqPow: 50,
    reqStr: 50,
    power: 29,
    knowledge: 28,
    hp: 80,
    mana: 200,
    endurance: 50,
    otherProperties: [["Podwójne losowanie trafienia", 2, 1], ["Modyfikator obrażeń magicznych", 2, 1]]
  },
  {
    name: "Inavoxy",
    type: "shield",
    rarity: "Psychorare",
    image: "mag120_def.png",
    reqLvl: 120,
    reqPow: 120,
    reqAgi: 50,
    reqKno: 120,
    power: 14,
    knowledge: 10,
    hp: 520,
    mana: 90,
    endurance: 50,
    otherProperties: [["Obrona wręcz", 2, 1], ["Obrona dystansowa", 2, 1]]
  },
  {
    name: "Berglisy",
    type: "shield",
    rarity: "Rare",
    randomStats: true,
    image: "mag60_def.png",
    reqLvl: 60,
    reqAgi: 30,
    reqKno: 50,
    power: 12,
    knowledge: 18,
    hp: 240,
    mana: 60,
  },
  {
    name: "Geury",
    type: "shield",
    rarity: "Rare",
    randomStats: true,
    image: "fiz60_def.png",
    reqLvl: 60,
    reqAgi: 50,
    reqKno: 30,
    strength: 12,
    agility: 18,
    hp: 240,
    endurance: 60,
  },
  {
    name: "Undurisy",
    type: "shield",
    rarity: "Psychorare",
    image: "fiz120_att.png",
    reqLvl: 120,
    reqPow: 50,
    reqStr: 50,
    strength: 43,
    agility: 27,
    hp: 40,
    mana: 40,
    endurance: 120,
    otherProperties: [["Podwójne losowanie trafienia", 2, 1], ["Modyfikator obrażeń fizycznych", 2, 1]]
  },
  {
    name: "Takerony",
    type: "shield",
    rarity: "Psychorare",
    image: "fiz120_def.png",
    reqLvl: 120,
    reqStr: 120,
    reqAgi: 120,
    reqKno: 50,
    strength: 10,
    agility: 15,
    hp: 500,
    mana: 50,
    endurance: 100,
    otherProperties: [["Obrona przeciw urokom", 2, 1], ["Obrona dystansowa", 2, 1]]
  },
]

const items: Item[] = itemsData.map((item) => new Item(item));
const itemsDatabase = items.sort((x, y) => x.reqLvl - y.reqLvl);

export default itemsDatabase
